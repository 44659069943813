import ExitToApp from '@material-ui/icons/ExitToApp';
import { signOut } from 'features/authentication/authentication-saga';
import { getCompanyProfile } from 'features/profile/profile-reducer';
import { fetchProfile } from 'features/profile/profile-saga';
import { hasFinancials } from 'features/vouchers/vouchers-reducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import contactOnDark from '../assets/images/nav/contactOnDark.png';
import contactOnLight from '../assets/images/nav/contactOnLight.png';
import financialsOnDark from '../assets/images/nav/financialsOnDark.png';
import financialsOnLight from '../assets/images/nav/financialsOnLight.png';
import vouchersOnDark from '../assets/images/nav/vouchersOnDark.png';
import vouchersOnLight from '../assets/images/nav/vouchersOnLight.png';
function MainLayout(props) {
  const location = useLocation();

  const [activePath, setActivePath] = useState('');

  const dispatch = useDispatch();

  let pathname = location.pathname;

  const companyState = useSelector(state => getCompanyProfile(state));
  const isZero = useSelector(state => hasFinancials(state));

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActivePath(pathname);
  }, [pathname]);

  const onSignout = () => {
    localStorage.clear();
    dispatch(signOut());
  };

  return (
    <div>
      <div className="header-all">
        <div className="header-top">
          <div className="header-top-airline">
            <img
              className="header-top-img"
              src={companyState?.photoURL}
              alt=""
            />
            <a href="/">
              <h1>{companyState?.companyName}</h1>
            </a>
          </div>
          <p className="header-top-powered pb-3">powered by Caasco</p>
          <button onClick={onSignout}>
            <small className="text-white mt-5">
              <ExitToApp />
            </small>
          </button>
        </div>
        <nav className="nav-bar-box">
          <ul className="nav-bar">
            <NavLink
              className="nav-atom"
              to={'/vouchers'}
              activeClassName="active"
            >
              <li className="mx-auto flex-col	 flex items-center justify-center">
                <img
                  className="pb-1"
                  src={
                    activePath.includes('/vouchers')
                      ? vouchersOnLight
                      : vouchersOnDark
                  }
                  alt=""
                />
                <p>Vouchers</p>
              </li>
            </NavLink>
            {!isZero && (
              <NavLink
                className="nav-atom"
                to={'/financials'}
                activeClassName="active"
              >
                <li className="mx-auto flex-col	 flex items-center justify-center">
                  <img
                    className="pb-1"
                    src={
                      activePath.includes('/financials')
                        ? financialsOnLight
                        : financialsOnDark
                    }
                    alt=""
                  />
                  <p>Financials</p>
                </li>
              </NavLink>
            )}

            <NavLink
              className="nav-atom"
              to={'/contact'}
              activeClassName="active"
            >
              <li className="mx-auto flex-col	 flex items-center justify-center">
                <img
                  className="pb-1"
                  src={
                    activePath.includes('/contact')
                      ? contactOnLight
                      : contactOnDark
                  }
                  alt=""
                />
                <p>Contact</p>
              </li>
            </NavLink>
          </ul>
        </nav>
      </div>
      <div className="header-spacer" />
      <div className="mt-28 pt-24 w-full items-center flex justify-center">
        {props.children}
      </div>
    </div>
  );
}

export default MainLayout;
