import { API } from 'aws-amplify';
import {
  getAuth,
  getPerson,
  setPerson,
} from 'features/authentication/authentication-reducer';
import { getCompanyProfile } from 'features/profile/profile-reducer';
import {
  getClaim,
  getVouchers,
  hasFinancials,
  setCashDispatchSuccess,
  setClaim,
  setError,
  setLoading,
  setVouchers,
} from 'features/vouchers/vouchers-reducer';
import { updateVoucher } from 'graphql/mutations';
import {
  getClaim as getClaimQuery,
  getPerson as getPersonQuery,
  voucherByPersonId,
} from 'graphql/queries';
import history from 'helpers/browser';
import toast from 'react-hot-toast';
import { call, put, select, takeEvery } from 'redux-saga/effects';
function* handleFetchPerson() {
  try {
    const auth = yield select(getAuth);
    const { data: person } = yield call([API, 'graphql'], {
      query: getPersonQuery,
      variables: {
        id: auth.atrributes?.preferred_username,
      },
    });
    yield put(setPerson(person.getPerson));
  } catch (error) {
    console.log(error);
  }
}
function* handleFetchVouchers() {
  try {
    yield put(setError(''));
    yield put(setLoading(true));

    yield call(handleFetchPerson);
    const person = yield select(getPerson);
    const { data: vouchers } = yield call([API, 'graphql'], {
      query: voucherByPersonId,
      variables: {
        personID: person.id,
      },
    });
    const { data: claim } = yield call([API, 'graphql'], {
      query: getClaimQuery,
      variables: {
        id: person.claimID,
      },
    });
    yield put(setClaim(claim.getClaim));
    yield put(
      setVouchers(
        vouchers.voucherByPersonId.items.filter(
          v => v.claimID === person.claimID,
        ),
      ),
    );

    yield put(setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
    yield put(setError('Error fetching vouchers'));
  }
}

const vouchers = payload => ({ type: vouchers.type, payload });
vouchers.type = 'VOUCHERS/fetchVouchers';

function* handleRejectOrAcceptVoucher({ payload }) {
  try {
    const { id, usageStatus } = payload;
    yield put(setLoading(true));
    yield call([API, 'graphql'], {
      query: updateVoucher,
      variables: {
        input: payload,
      },
    });
    let vouchersList = yield select(getVouchers);
    let voucherItem = vouchersList.find(v => v.id === id);
    let update = {
      ...voucherItem,
      usageStatus,
    };
    let newVouchersList = vouchersList.filter(v => v.id !== id);
    newVouchersList.push(update);
    yield put(setVouchers(newVouchersList));
    const isEmpty = yield select(hasFinancials);

    if (isEmpty) {
      yield call([history, 'push'], '/voucher');
    }
    yield put(setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}
const voucherUsageStatus = payload => ({
  type: voucherUsageStatus.type,
  payload,
});
voucherUsageStatus.type = 'VOUCHERS/rejectVoucher';

function* watchFetchVouchers() {
  yield takeEvery(vouchers.type, handleFetchVouchers);
}

function* handleSendCashCompensation({ payload }) {
  try {
    yield put(setLoading(true));
    yield put(setError(''));
    const {
      accountHolder,
      bankName,
      country,
      ibanNumber,
      swiftCode,
      address,
      accountNumber,
      city,
      reason,
      amount,
      reference,
      id,
    } = payload;
    const user = yield select(getPerson);
    yield put(setCashDispatchSuccess(false));
    let company = yield select(getCompanyProfile);
    let apiName = 'financeApi';
    let path = `/beneficiary`;
    let data = {
      response: false,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: {
        bank_account_holder_name: accountHolder,
        bank_name: bankName,
        tenant: company?.tenant,
        currency: company.currency,
        email: user?.email,
        country: country,
        iban: ibanNumber,
        bic_swift: swiftCode,
        first_name: user?.firstName,
        last_name: user?.lastName,
        address,
        city,
        account_id: company?.walletID,
        account_number: accountNumber,
      },
    };
    const response = yield call([API, 'post'], apiName, path, data);
    const claim = yield select(getClaim);
    path = '/payments';
    data = {
      response: false,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: {
        amount,
        reason,
        tenant: company?.tenant,
        reference,
        beneficiary_id: response?.cc_id,
        currency: company.currency,
        account_id: company?.walletID,
        type: 'bank_transfer',
        username: claim?.lastEditorID,
      },
    };

    yield call([API, 'post'], apiName, path, data);
    const vouchers = yield select(getVouchers);
    const v = vouchers.find(v => v.id === id);
    const newAmount = v.fincompDetails.amount - amount;
    yield call([API, 'graphql'], {
      query: updateVoucher,
      variables: {
        input: {
          id,
          usageStatus: 'ACCEPTED',
          fincompDetails: {
            ...v.fincompDetails,
            amount: newAmount,
          },
        },
      },
    });
    toast.success('payment has been successfully dispatched');
    yield put(setLoading(false));
    yield put(setCashDispatchSuccess(true));
  } catch (error) {
    yield put(setCashDispatchSuccess(false));
    yield put(setError('Somthing went wrong'));
    console.log(error);
    yield put(setLoading(false));
  }
}
const sendCashCompensation = payload => ({
  type: sendCashCompensation.type,
  payload,
});
sendCashCompensation.type = 'VOUCHERS/sendCashCompensation';
function* watchRejectOrAcceptVoucher() {
  yield takeEvery(voucherUsageStatus.type, handleRejectOrAcceptVoucher);
}
function* watchsendCashCompensation() {
  yield takeEvery(sendCashCompensation.type, handleSendCashCompensation);
}

export {
  watchFetchVouchers,
  vouchers,
  voucherUsageStatus,
  watchRejectOrAcceptVoucher,
  sendCashCompensation,
  watchsendCashCompensation,
};
