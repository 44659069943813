import { lazy } from 'react';

const Login = lazy(() => import('../views/Login'));
const Authorize = lazy(() =>
  import('../features/authentication/Authorize/authorize-container'),
);
const Financials = lazy(() => import('../views/Financials'));
const Contact = lazy(() => import('../views/Contact'));
const Vouchers = lazy(() => import('../views/Vouchers'));
const CashCompensation = lazy(() =>
  import('../views/Financials/CashCompensation'),
);
const AviosPoints = lazy(() => import('../views/Financials/AviosPoints'));
const SplitCompensation = lazy(() =>
  import('../views/Financials/SplitCompensation'),
);
const Evoucher = lazy(() => import('../views/Financials/EVoucher'));

const routes = [
  {
    component: Financials,
    layout: 'Main',
    path: '/financials',
    private: true,
    permissions: [],
  },
  {
    component: Contact,
    layout: 'Main',
    path: '/contact',
    private: true,
    permissions: [],
  },
  {
    component: Vouchers,
    layout: 'Main',
    path: '/vouchers',
    private: true,
    permissions: [],
  },

  {
    component: CashCompensation,
    layout: 'Main',
    path: '/financials/cash',
    private: true,
    permissions: [],
  },
  {
    component: AviosPoints,
    layout: 'Main',
    path: '/financials/aviospoints',
    private: true,
    permissions: [],
  },
  {
    component: Evoucher,
    layout: 'Main',
    path: '/financials/evoucher',
    private: true,
    permissions: [],
  },
  {
    component: SplitCompensation,
    layout: 'Main',
    path: '/financials/splitamount',
    private: true,
    permissions: [],
  },
  {
    component: Login,
    layout: 'Blank',
    path: '/login',
    private: false,
    permissions: [],
  },
  {
    component: Authorize,
    layout: 'Blank',
    path: '/authorize/:challenge',
    private: false,
    permissions: [],
  },
];

export default routes;
