/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      tenant
      accountType
      businessName
      active
      photoURL
      createdAt
      updatedAt
      wallet {
        id
        accountID
        paymentsVendor
        bic
        iban
        bankName
        currency
        accountNo
        shortCode
        createdAt
        updatedAt
      }
      companies {
        items {
          id
          tenant
          companyType
          accountID
          walletID
          companyName
          currency
          active
          photoURL
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      tenant
      accountType
      businessName
      active
      photoURL
      createdAt
      updatedAt
      wallet {
        id
        accountID
        paymentsVendor
        bic
        iban
        bankName
        currency
        accountNo
        shortCode
        createdAt
        updatedAt
      }
      companies {
        items {
          id
          tenant
          companyType
          accountID
          walletID
          companyName
          currency
          active
          photoURL
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      tenant
      accountType
      businessName
      active
      photoURL
      createdAt
      updatedAt
      wallet {
        id
        accountID
        paymentsVendor
        bic
        iban
        bankName
        currency
        accountNo
        shortCode
        createdAt
        updatedAt
      }
      companies {
        items {
          id
          tenant
          companyType
          accountID
          walletID
          companyName
          currency
          active
          photoURL
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $input: CreateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    createWallet(input: $input, condition: $condition) {
      id
      accountID
      paymentsVendor
      bic
      iban
      bankName
      currency
      accountNo
      shortCode
      createdAt
      updatedAt
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet(
    $input: UpdateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    updateWallet(input: $input, condition: $condition) {
      id
      accountID
      paymentsVendor
      bic
      iban
      bankName
      currency
      accountNo
      shortCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet(
    $input: DeleteWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    deleteWallet(input: $input, condition: $condition) {
      id
      accountID
      paymentsVendor
      bic
      iban
      bankName
      currency
      accountNo
      shortCode
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      tenant
      companyID
      identityID
      firstName
      lastName
      email
      contactNumber
      jobTitle
      active
      enableOnboarding
      permissionLevel
      permissionRegion
      country
      photoFilename
      userPreferences {
        timezone
        language
        currency
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      tenant
      companyID
      identityID
      firstName
      lastName
      email
      contactNumber
      jobTitle
      active
      enableOnboarding
      permissionLevel
      permissionRegion
      country
      photoFilename
      userPreferences {
        timezone
        language
        currency
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      tenant
      companyID
      identityID
      firstName
      lastName
      email
      contactNumber
      jobTitle
      active
      enableOnboarding
      permissionLevel
      permissionRegion
      country
      photoFilename
      userPreferences {
        timezone
        language
        currency
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      tenant
      companyType
      accountID
      walletID
      companyName
      address {
        addressline1
        addressline2
        town
        county
        postcode
        country
      }
      currency
      active
      primaryContact {
        name
        phone
        email
      }
      secondaryContact {
        name
        phone
        email
      }
      coordinates {
        latitude
        longitude
      }
      voucherScans {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      hotelAgreements {
        id
        tenant
        hotelAirlineID
        lastEditorID
        hotelID
        airlineID
        agreementName
        agreementDescription
        rateAccessCode
        dateIssued
        dateLastEdited
        roomType
        roomRateArray {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        mealRateArray {
          breakfast
          lunch
          dinner
        }
        agreementValidFrom
        agreementValidTo
        agreementStatus
        active
        reservationEmail
        reservationPhone
        terminal
        state
        postalCode
        isArchived
        createdAt
        updatedAt
        hotelAirline {
          id
          tenant
          airlineID
          hotelID
          lastEditorID
          contractHotel
          premiumHotel
          onShuttleRoute
          active
          starsRating
          hotelName
          printedHotelName
          address
          city
          currency
          poplSupplier
          fax
          rank
          terminal
          contactFirstName
          contactLastName
          contactEmail
          reservationEmail
          country
          postalCode
          state
          contactNumber
          contactFax
          isArchived
          createdAt
          updatedAt
        }
      }
      airlineDetails {
        compensationPreferences {
          vouchers
          financial
          transportation
          hotel
          loyaltypoints
          downgrade
        }
        communicationPreferences
        defaultPreferences {
          cancelled
          downgrade
          denied
          delay
          firstNeeds
        }
        airportIATA
        airlineIATA
      }
      photoURL
      createdAt
      updatedAt
      users {
        items {
          id
          tenant
          companyID
          identityID
          firstName
          lastName
          email
          contactNumber
          jobTitle
          active
          enableOnboarding
          permissionLevel
          permissionRegion
          country
          photoFilename
          createdAt
          updatedAt
        }
        nextToken
      }
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      tenant
      companyType
      accountID
      walletID
      companyName
      address {
        addressline1
        addressline2
        town
        county
        postcode
        country
      }
      currency
      active
      primaryContact {
        name
        phone
        email
      }
      secondaryContact {
        name
        phone
        email
      }
      coordinates {
        latitude
        longitude
      }
      voucherScans {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      hotelAgreements {
        id
        tenant
        hotelAirlineID
        lastEditorID
        hotelID
        airlineID
        agreementName
        agreementDescription
        rateAccessCode
        dateIssued
        dateLastEdited
        roomType
        roomRateArray {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        mealRateArray {
          breakfast
          lunch
          dinner
        }
        agreementValidFrom
        agreementValidTo
        agreementStatus
        active
        reservationEmail
        reservationPhone
        terminal
        state
        postalCode
        isArchived
        createdAt
        updatedAt
        hotelAirline {
          id
          tenant
          airlineID
          hotelID
          lastEditorID
          contractHotel
          premiumHotel
          onShuttleRoute
          active
          starsRating
          hotelName
          printedHotelName
          address
          city
          currency
          poplSupplier
          fax
          rank
          terminal
          contactFirstName
          contactLastName
          contactEmail
          reservationEmail
          country
          postalCode
          state
          contactNumber
          contactFax
          isArchived
          createdAt
          updatedAt
        }
      }
      airlineDetails {
        compensationPreferences {
          vouchers
          financial
          transportation
          hotel
          loyaltypoints
          downgrade
        }
        communicationPreferences
        defaultPreferences {
          cancelled
          downgrade
          denied
          delay
          firstNeeds
        }
        airportIATA
        airlineIATA
      }
      photoURL
      createdAt
      updatedAt
      users {
        items {
          id
          tenant
          companyID
          identityID
          firstName
          lastName
          email
          contactNumber
          jobTitle
          active
          enableOnboarding
          permissionLevel
          permissionRegion
          country
          photoFilename
          createdAt
          updatedAt
        }
        nextToken
      }
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      tenant
      companyType
      accountID
      walletID
      companyName
      address {
        addressline1
        addressline2
        town
        county
        postcode
        country
      }
      currency
      active
      primaryContact {
        name
        phone
        email
      }
      secondaryContact {
        name
        phone
        email
      }
      coordinates {
        latitude
        longitude
      }
      voucherScans {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      hotelAgreements {
        id
        tenant
        hotelAirlineID
        lastEditorID
        hotelID
        airlineID
        agreementName
        agreementDescription
        rateAccessCode
        dateIssued
        dateLastEdited
        roomType
        roomRateArray {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        mealRateArray {
          breakfast
          lunch
          dinner
        }
        agreementValidFrom
        agreementValidTo
        agreementStatus
        active
        reservationEmail
        reservationPhone
        terminal
        state
        postalCode
        isArchived
        createdAt
        updatedAt
        hotelAirline {
          id
          tenant
          airlineID
          hotelID
          lastEditorID
          contractHotel
          premiumHotel
          onShuttleRoute
          active
          starsRating
          hotelName
          printedHotelName
          address
          city
          currency
          poplSupplier
          fax
          rank
          terminal
          contactFirstName
          contactLastName
          contactEmail
          reservationEmail
          country
          postalCode
          state
          contactNumber
          contactFax
          isArchived
          createdAt
          updatedAt
        }
      }
      airlineDetails {
        compensationPreferences {
          vouchers
          financial
          transportation
          hotel
          loyaltypoints
          downgrade
        }
        communicationPreferences
        defaultPreferences {
          cancelled
          downgrade
          denied
          delay
          firstNeeds
        }
        airportIATA
        airlineIATA
      }
      photoURL
      createdAt
      updatedAt
      users {
        items {
          id
          tenant
          companyID
          identityID
          firstName
          lastName
          email
          contactNumber
          jobTitle
          active
          enableOnboarding
          permissionLevel
          permissionRegion
          country
          photoFilename
          createdAt
          updatedAt
        }
        nextToken
      }
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createHotelAirline = /* GraphQL */ `
  mutation CreateHotelAirline(
    $input: CreateHotelAirlineInput!
    $condition: ModelHotelAirlineConditionInput
  ) {
    createHotelAirline(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      hotelID
      lastEditorID
      contractHotel
      premiumHotel
      onShuttleRoute
      active
      starsRating
      hotelName
      printedHotelName
      address
      city
      currency
      poplSupplier
      fax
      rank
      terminal
      contactFirstName
      contactLastName
      contactEmail
      reservationEmail
      country
      postalCode
      state
      contactNumber
      contactFax
      isArchived
      createdAt
      updatedAt
      agreements {
        items {
          id
          tenant
          hotelAirlineID
          lastEditorID
          hotelID
          airlineID
          agreementName
          agreementDescription
          rateAccessCode
          dateIssued
          dateLastEdited
          roomType
          agreementValidFrom
          agreementValidTo
          agreementStatus
          active
          reservationEmail
          reservationPhone
          terminal
          state
          postalCode
          isArchived
          createdAt
          updatedAt
        }
        nextToken
      }
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateHotelAirline = /* GraphQL */ `
  mutation UpdateHotelAirline(
    $input: UpdateHotelAirlineInput!
    $condition: ModelHotelAirlineConditionInput
  ) {
    updateHotelAirline(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      hotelID
      lastEditorID
      contractHotel
      premiumHotel
      onShuttleRoute
      active
      starsRating
      hotelName
      printedHotelName
      address
      city
      currency
      poplSupplier
      fax
      rank
      terminal
      contactFirstName
      contactLastName
      contactEmail
      reservationEmail
      country
      postalCode
      state
      contactNumber
      contactFax
      isArchived
      createdAt
      updatedAt
      agreements {
        items {
          id
          tenant
          hotelAirlineID
          lastEditorID
          hotelID
          airlineID
          agreementName
          agreementDescription
          rateAccessCode
          dateIssued
          dateLastEdited
          roomType
          agreementValidFrom
          agreementValidTo
          agreementStatus
          active
          reservationEmail
          reservationPhone
          terminal
          state
          postalCode
          isArchived
          createdAt
          updatedAt
        }
        nextToken
      }
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteHotelAirline = /* GraphQL */ `
  mutation DeleteHotelAirline(
    $input: DeleteHotelAirlineInput!
    $condition: ModelHotelAirlineConditionInput
  ) {
    deleteHotelAirline(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      hotelID
      lastEditorID
      contractHotel
      premiumHotel
      onShuttleRoute
      active
      starsRating
      hotelName
      printedHotelName
      address
      city
      currency
      poplSupplier
      fax
      rank
      terminal
      contactFirstName
      contactLastName
      contactEmail
      reservationEmail
      country
      postalCode
      state
      contactNumber
      contactFax
      isArchived
      createdAt
      updatedAt
      agreements {
        items {
          id
          tenant
          hotelAirlineID
          lastEditorID
          hotelID
          airlineID
          agreementName
          agreementDescription
          rateAccessCode
          dateIssued
          dateLastEdited
          roomType
          agreementValidFrom
          agreementValidTo
          agreementStatus
          active
          reservationEmail
          reservationPhone
          terminal
          state
          postalCode
          isArchived
          createdAt
          updatedAt
        }
        nextToken
      }
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createHotelAgreement = /* GraphQL */ `
  mutation CreateHotelAgreement(
    $input: CreateHotelAgreementInput!
    $condition: ModelHotelAgreementConditionInput
  ) {
    createHotelAgreement(input: $input, condition: $condition) {
      id
      tenant
      hotelAirlineID
      lastEditorID
      hotelID
      airlineID
      agreementName
      agreementDescription
      rateAccessCode
      dateIssued
      dateLastEdited
      roomType
      roomRateArray {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      mealRateArray {
        breakfast
        lunch
        dinner
      }
      agreementValidFrom
      agreementValidTo
      agreementStatus
      active
      reservationEmail
      reservationPhone
      terminal
      state
      postalCode
      isArchived
      createdAt
      updatedAt
      hotelAirline {
        id
        tenant
        airlineID
        hotelID
        lastEditorID
        contractHotel
        premiumHotel
        onShuttleRoute
        active
        starsRating
        hotelName
        printedHotelName
        address
        city
        currency
        poplSupplier
        fax
        rank
        terminal
        contactFirstName
        contactLastName
        contactEmail
        reservationEmail
        country
        postalCode
        state
        contactNumber
        contactFax
        isArchived
        createdAt
        updatedAt
        agreements {
          nextToken
        }
        roomRequests {
          nextToken
        }
      }
    }
  }
`;
export const updateHotelAgreement = /* GraphQL */ `
  mutation UpdateHotelAgreement(
    $input: UpdateHotelAgreementInput!
    $condition: ModelHotelAgreementConditionInput
  ) {
    updateHotelAgreement(input: $input, condition: $condition) {
      id
      tenant
      hotelAirlineID
      lastEditorID
      hotelID
      airlineID
      agreementName
      agreementDescription
      rateAccessCode
      dateIssued
      dateLastEdited
      roomType
      roomRateArray {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      mealRateArray {
        breakfast
        lunch
        dinner
      }
      agreementValidFrom
      agreementValidTo
      agreementStatus
      active
      reservationEmail
      reservationPhone
      terminal
      state
      postalCode
      isArchived
      createdAt
      updatedAt
      hotelAirline {
        id
        tenant
        airlineID
        hotelID
        lastEditorID
        contractHotel
        premiumHotel
        onShuttleRoute
        active
        starsRating
        hotelName
        printedHotelName
        address
        city
        currency
        poplSupplier
        fax
        rank
        terminal
        contactFirstName
        contactLastName
        contactEmail
        reservationEmail
        country
        postalCode
        state
        contactNumber
        contactFax
        isArchived
        createdAt
        updatedAt
        agreements {
          nextToken
        }
        roomRequests {
          nextToken
        }
      }
    }
  }
`;
export const deleteHotelAgreement = /* GraphQL */ `
  mutation DeleteHotelAgreement(
    $input: DeleteHotelAgreementInput!
    $condition: ModelHotelAgreementConditionInput
  ) {
    deleteHotelAgreement(input: $input, condition: $condition) {
      id
      tenant
      hotelAirlineID
      lastEditorID
      hotelID
      airlineID
      agreementName
      agreementDescription
      rateAccessCode
      dateIssued
      dateLastEdited
      roomType
      roomRateArray {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      mealRateArray {
        breakfast
        lunch
        dinner
      }
      agreementValidFrom
      agreementValidTo
      agreementStatus
      active
      reservationEmail
      reservationPhone
      terminal
      state
      postalCode
      isArchived
      createdAt
      updatedAt
      hotelAirline {
        id
        tenant
        airlineID
        hotelID
        lastEditorID
        contractHotel
        premiumHotel
        onShuttleRoute
        active
        starsRating
        hotelName
        printedHotelName
        address
        city
        currency
        poplSupplier
        fax
        rank
        terminal
        contactFirstName
        contactLastName
        contactEmail
        reservationEmail
        country
        postalCode
        state
        contactNumber
        contactFax
        isArchived
        createdAt
        updatedAt
        agreements {
          nextToken
        }
        roomRequests {
          nextToken
        }
      }
    }
  }
`;
export const createRoomRequest = /* GraphQL */ `
  mutation CreateRoomRequest(
    $input: CreateRoomRequestInput!
    $condition: ModelRoomRequestConditionInput
  ) {
    createRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      requestID
      airlineID
      userID
      hotelID
      multiRoomRequestID
      HotelAirlineID
      roomsRequested
      nights
      bookingDate
      requestType
      requestStatus
      validFrom
      validUntil
      roomsConfirmed
      roomsUsed
      isArchived
      multiRequest
      createdAt
      updatedAt
    }
  }
`;
export const updateRoomRequest = /* GraphQL */ `
  mutation UpdateRoomRequest(
    $input: UpdateRoomRequestInput!
    $condition: ModelRoomRequestConditionInput
  ) {
    updateRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      requestID
      airlineID
      userID
      hotelID
      multiRoomRequestID
      HotelAirlineID
      roomsRequested
      nights
      bookingDate
      requestType
      requestStatus
      validFrom
      validUntil
      roomsConfirmed
      roomsUsed
      isArchived
      multiRequest
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoomRequest = /* GraphQL */ `
  mutation DeleteRoomRequest(
    $input: DeleteRoomRequestInput!
    $condition: ModelRoomRequestConditionInput
  ) {
    deleteRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      requestID
      airlineID
      userID
      hotelID
      multiRoomRequestID
      HotelAirlineID
      roomsRequested
      nights
      bookingDate
      requestType
      requestStatus
      validFrom
      validUntil
      roomsConfirmed
      roomsUsed
      isArchived
      multiRequest
      createdAt
      updatedAt
    }
  }
`;
export const createDirectBooking = /* GraphQL */ `
  mutation CreateDirectBooking(
    $input: CreateDirectBookingInput!
    $condition: ModelDirectBookingConditionInput
  ) {
    createDirectBooking(input: $input, condition: $condition) {
      id
      tenant
      airline
      date
      distanceFromAirport
      maxPricePerNight
      maxStarRatings
      minPricePerNight
      minStarRatings
      nights
      propertyType
      roomType
      hotelName
      rooms
      status
      createdAt
      updatedAt
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDirectBooking = /* GraphQL */ `
  mutation UpdateDirectBooking(
    $input: UpdateDirectBookingInput!
    $condition: ModelDirectBookingConditionInput
  ) {
    updateDirectBooking(input: $input, condition: $condition) {
      id
      tenant
      airline
      date
      distanceFromAirport
      maxPricePerNight
      maxStarRatings
      minPricePerNight
      minStarRatings
      nights
      propertyType
      roomType
      hotelName
      rooms
      status
      createdAt
      updatedAt
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDirectBooking = /* GraphQL */ `
  mutation DeleteDirectBooking(
    $input: DeleteDirectBookingInput!
    $condition: ModelDirectBookingConditionInput
  ) {
    deleteDirectBooking(input: $input, condition: $condition) {
      id
      tenant
      airline
      date
      distanceFromAirport
      maxPricePerNight
      maxStarRatings
      minPricePerNight
      minStarRatings
      nights
      propertyType
      roomType
      hotelName
      rooms
      status
      createdAt
      updatedAt
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMultiRoomRequest = /* GraphQL */ `
  mutation CreateMultiRoomRequest(
    $input: CreateMultiRoomRequestInput!
    $condition: ModelMultiRoomRequestConditionInput
  ) {
    createMultiRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      userID
      roomsRequested
      roomsConfirmed
      nights
      requestType
      requestStatus
      validFrom
      validUntil
      hotels {
        id
        name
      }
      createdAt
      updatedAt
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateMultiRoomRequest = /* GraphQL */ `
  mutation UpdateMultiRoomRequest(
    $input: UpdateMultiRoomRequestInput!
    $condition: ModelMultiRoomRequestConditionInput
  ) {
    updateMultiRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      userID
      roomsRequested
      roomsConfirmed
      nights
      requestType
      requestStatus
      validFrom
      validUntil
      hotels {
        id
        name
      }
      createdAt
      updatedAt
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteMultiRoomRequest = /* GraphQL */ `
  mutation DeleteMultiRoomRequest(
    $input: DeleteMultiRoomRequestInput!
    $condition: ModelMultiRoomRequestConditionInput
  ) {
    deleteMultiRoomRequest(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      userID
      roomsRequested
      roomsConfirmed
      nights
      requestType
      requestStatus
      validFrom
      validUntil
      hotels {
        id
        name
      }
      createdAt
      updatedAt
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      lastEditorID
      flightNumber
      companyID
      flightDate
      type
      reason
      createdAt
      updatedAt
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      flightID {
        items {
          id
          tenant
          claimID
          flightID
          flightNumber
          flightDate
          source
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      lastEditorID
      flightNumber
      companyID
      flightDate
      type
      reason
      createdAt
      updatedAt
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      flightID {
        items {
          id
          tenant
          claimID
          flightID
          flightNumber
          flightDate
          source
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      lastEditorID
      flightNumber
      companyID
      flightDate
      type
      reason
      createdAt
      updatedAt
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      flightID {
        items {
          id
          tenant
          claimID
          flightID
          flightNumber
          flightDate
          source
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      tenant
      companyID
      personType
      firstName
      lastName
      claimID
      email
      contactNumber
      pnr
      dob
      status
      cabinBooked
      cabinAssigned
      deniedBoarding
      passengerNotes
      staffDetails {
        staffID
        staffNotes
      }
      createdAt
      updatedAt
      claim {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      transportBookings {
        items {
          id
          tenant
          requestID
          pickup_dt
          payment_method
          service
          PersonID
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      tenant
      companyID
      personType
      firstName
      lastName
      claimID
      email
      contactNumber
      pnr
      dob
      status
      cabinBooked
      cabinAssigned
      deniedBoarding
      passengerNotes
      staffDetails {
        staffID
        staffNotes
      }
      createdAt
      updatedAt
      claim {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      transportBookings {
        items {
          id
          tenant
          requestID
          pickup_dt
          payment_method
          service
          PersonID
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      tenant
      companyID
      personType
      firstName
      lastName
      claimID
      email
      contactNumber
      pnr
      dob
      status
      cabinBooked
      cabinAssigned
      deniedBoarding
      passengerNotes
      staffDetails {
        staffID
        staffNotes
      }
      createdAt
      updatedAt
      claim {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      transportBookings {
        items {
          id
          tenant
          requestID
          pickup_dt
          payment_method
          service
          PersonID
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createResident = /* GraphQL */ `
  mutation CreateResident(
    $input: CreateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    createResident(input: $input, condition: $condition) {
      id
      tenant
      companyID
      address
      firstName
      lastName
      email
      phone
      dob
      city
      hasPet
      wheelchair
      numberOfRooms
      createdAt
      updatedAt
    }
  }
`;
export const updateResident = /* GraphQL */ `
  mutation UpdateResident(
    $input: UpdateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    updateResident(input: $input, condition: $condition) {
      id
      tenant
      companyID
      address
      firstName
      lastName
      email
      phone
      dob
      city
      hasPet
      wheelchair
      numberOfRooms
      createdAt
      updatedAt
    }
  }
`;
export const deleteResident = /* GraphQL */ `
  mutation DeleteResident(
    $input: DeleteResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    deleteResident(input: $input, condition: $condition) {
      id
      tenant
      companyID
      address
      firstName
      lastName
      email
      phone
      dob
      city
      hasPet
      wheelchair
      numberOfRooms
      createdAt
      updatedAt
    }
  }
`;
export const createTransportBooking = /* GraphQL */ `
  mutation CreateTransportBooking(
    $input: CreateTransportBookingInput!
    $condition: ModelTransportBookingConditionInput
  ) {
    createTransportBooking(input: $input, condition: $condition) {
      id
      tenant
      requestID
      pickup_dt
      payment_method
      service
      pickupLocation {
        lat
        long
        street_address
        town
        country
      }
      PersonID
      createdAt
      updatedAt
      Person {
        id
        tenant
        companyID
        personType
        firstName
        lastName
        claimID
        email
        contactNumber
        pnr
        dob
        status
        cabinBooked
        cabinAssigned
        deniedBoarding
        passengerNotes
        staffDetails {
          staffID
          staffNotes
        }
        createdAt
        updatedAt
        claim {
          id
          tenant
          airlineID
          lastEditorID
          flightNumber
          companyID
          flightDate
          type
          reason
          createdAt
          updatedAt
        }
        transportBookings {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
    }
  }
`;
export const updateTransportBooking = /* GraphQL */ `
  mutation UpdateTransportBooking(
    $input: UpdateTransportBookingInput!
    $condition: ModelTransportBookingConditionInput
  ) {
    updateTransportBooking(input: $input, condition: $condition) {
      id
      tenant
      requestID
      pickup_dt
      payment_method
      service
      pickupLocation {
        lat
        long
        street_address
        town
        country
      }
      PersonID
      createdAt
      updatedAt
      Person {
        id
        tenant
        companyID
        personType
        firstName
        lastName
        claimID
        email
        contactNumber
        pnr
        dob
        status
        cabinBooked
        cabinAssigned
        deniedBoarding
        passengerNotes
        staffDetails {
          staffID
          staffNotes
        }
        createdAt
        updatedAt
        claim {
          id
          tenant
          airlineID
          lastEditorID
          flightNumber
          companyID
          flightDate
          type
          reason
          createdAt
          updatedAt
        }
        transportBookings {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
    }
  }
`;
export const deleteTransportBooking = /* GraphQL */ `
  mutation DeleteTransportBooking(
    $input: DeleteTransportBookingInput!
    $condition: ModelTransportBookingConditionInput
  ) {
    deleteTransportBooking(input: $input, condition: $condition) {
      id
      tenant
      requestID
      pickup_dt
      payment_method
      service
      pickupLocation {
        lat
        long
        street_address
        town
        country
      }
      PersonID
      createdAt
      updatedAt
      Person {
        id
        tenant
        companyID
        personType
        firstName
        lastName
        claimID
        email
        contactNumber
        pnr
        dob
        status
        cabinBooked
        cabinAssigned
        deniedBoarding
        passengerNotes
        staffDetails {
          staffID
          staffNotes
        }
        createdAt
        updatedAt
        claim {
          id
          tenant
          airlineID
          lastEditorID
          flightNumber
          companyID
          flightDate
          type
          reason
          createdAt
          updatedAt
        }
        transportBookings {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
    }
  }
`;
export const createFlightStatus = /* GraphQL */ `
  mutation CreateFlightStatus(
    $input: CreateFlightStatusInput!
    $condition: ModelFlightStatusConditionInput
  ) {
    createFlightStatus(input: $input, condition: $condition) {
      id
      tenant
      claimID
      flightID
      flightNumber
      flightDate
      source
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateFlightStatus = /* GraphQL */ `
  mutation UpdateFlightStatus(
    $input: UpdateFlightStatusInput!
    $condition: ModelFlightStatusConditionInput
  ) {
    updateFlightStatus(input: $input, condition: $condition) {
      id
      tenant
      claimID
      flightID
      flightNumber
      flightDate
      source
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlightStatus = /* GraphQL */ `
  mutation DeleteFlightStatus(
    $input: DeleteFlightStatusInput!
    $condition: ModelFlightStatusConditionInput
  ) {
    deleteFlightStatus(input: $input, condition: $condition) {
      id
      tenant
      claimID
      flightID
      flightNumber
      flightDate
      source
      data
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      tenant
      messageID
      claimID
      recipientID
      senderID
      airlineID
      messageVendor
      messageType
      workflowStage
      messageStatus
      dateSent
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      tenant
      messageID
      claimID
      recipientID
      senderID
      airlineID
      messageVendor
      messageType
      workflowStage
      messageStatus
      dateSent
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      tenant
      messageID
      claimID
      recipientID
      senderID
      airlineID
      messageVendor
      messageType
      workflowStage
      messageStatus
      dateSent
      createdAt
      updatedAt
    }
  }
`;
export const createVoucher = /* GraphQL */ `
  mutation CreateVoucher(
    $input: CreateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    createVoucher(input: $input, condition: $condition) {
      id
      tenant
      voucherID
      voucherType
      userType
      personID
      claimID
      vendorID
      companyID
      usageStatus
      dateIssued
      validFrom
      validUntil
      directBookingID
      accommodationDetails {
        nights
        validUntil
        bookingDetails
        directBookingDetails
      }
      fincompDetails {
        amount
        currency
        fcOptions
        reason
        paymentReference {
          iban
          amount
          paymentID
          accountHolder
        }
      }
      tripDetails {
        tripID
        origin {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        destination {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        amount
        currency
        tripStatus
        transportType
        isFromAirport
      }
      lrvDetails {
        airport
        currency
        initialAmount
        currentAmount
      }
      createdAt
      updatedAt
      voucherActivity {
        items {
          id
          tenant
          airlineID
          voucherID
          vendorID
          voucherType
          currency
          amount
          flightNumber
          destination
          activityTimeStamp
          approval
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVoucher = /* GraphQL */ `
  mutation UpdateVoucher(
    $input: UpdateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    updateVoucher(input: $input, condition: $condition) {
      id
      tenant
      voucherID
      voucherType
      userType
      personID
      claimID
      vendorID
      companyID
      usageStatus
      dateIssued
      validFrom
      validUntil
      directBookingID
      accommodationDetails {
        nights
        validUntil
        bookingDetails
        directBookingDetails
      }
      fincompDetails {
        amount
        currency
        fcOptions
        reason
        paymentReference {
          iban
          amount
          paymentID
          accountHolder
        }
      }
      tripDetails {
        tripID
        origin {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        destination {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        amount
        currency
        tripStatus
        transportType
        isFromAirport
      }
      lrvDetails {
        airport
        currency
        initialAmount
        currentAmount
      }
      createdAt
      updatedAt
      voucherActivity {
        items {
          id
          tenant
          airlineID
          voucherID
          vendorID
          voucherType
          currency
          amount
          flightNumber
          destination
          activityTimeStamp
          approval
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVoucher = /* GraphQL */ `
  mutation DeleteVoucher(
    $input: DeleteVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    deleteVoucher(input: $input, condition: $condition) {
      id
      tenant
      voucherID
      voucherType
      userType
      personID
      claimID
      vendorID
      companyID
      usageStatus
      dateIssued
      validFrom
      validUntil
      directBookingID
      accommodationDetails {
        nights
        validUntil
        bookingDetails
        directBookingDetails
      }
      fincompDetails {
        amount
        currency
        fcOptions
        reason
        paymentReference {
          iban
          amount
          paymentID
          accountHolder
        }
      }
      tripDetails {
        tripID
        origin {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        destination {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        amount
        currency
        tripStatus
        transportType
        isFromAirport
      }
      lrvDetails {
        airport
        currency
        initialAmount
        currentAmount
      }
      createdAt
      updatedAt
      voucherActivity {
        items {
          id
          tenant
          airlineID
          voucherID
          vendorID
          voucherType
          currency
          amount
          flightNumber
          destination
          activityTimeStamp
          approval
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVoucherActivity = /* GraphQL */ `
  mutation CreateVoucherActivity(
    $input: CreateVoucherActivityInput!
    $condition: ModelVoucherActivityConditionInput
  ) {
    createVoucherActivity(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      voucherID
      vendorID
      voucherType
      currency
      amount
      flightNumber
      destination
      activityTimeStamp
      approval
      reason
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucherActivity = /* GraphQL */ `
  mutation UpdateVoucherActivity(
    $input: UpdateVoucherActivityInput!
    $condition: ModelVoucherActivityConditionInput
  ) {
    updateVoucherActivity(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      voucherID
      vendorID
      voucherType
      currency
      amount
      flightNumber
      destination
      activityTimeStamp
      approval
      reason
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucherActivity = /* GraphQL */ `
  mutation DeleteVoucherActivity(
    $input: DeleteVoucherActivityInput!
    $condition: ModelVoucherActivityConditionInput
  ) {
    deleteVoucherActivity(input: $input, condition: $condition) {
      id
      tenant
      airlineID
      voucherID
      vendorID
      voucherType
      currency
      amount
      flightNumber
      destination
      activityTimeStamp
      approval
      reason
      createdAt
      updatedAt
    }
  }
`;
export const createAirportRef = /* GraphQL */ `
  mutation CreateAirportRef(
    $input: CreateAirportRefInput!
    $condition: ModelAirportRefConditionInput
  ) {
    createAirportRef(input: $input, condition: $condition) {
      id
      tenant
      GMT
      codeIataAirport
      codeIcaoAirport
      codeIataCity
      codeIso2Country
      geonameId
      latitudeAirport
      longitudeAirport
      nameAirport
      nameCountry
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const updateAirportRef = /* GraphQL */ `
  mutation UpdateAirportRef(
    $input: UpdateAirportRefInput!
    $condition: ModelAirportRefConditionInput
  ) {
    updateAirportRef(input: $input, condition: $condition) {
      id
      tenant
      GMT
      codeIataAirport
      codeIcaoAirport
      codeIataCity
      codeIso2Country
      geonameId
      latitudeAirport
      longitudeAirport
      nameAirport
      nameCountry
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const deleteAirportRef = /* GraphQL */ `
  mutation DeleteAirportRef(
    $input: DeleteAirportRefInput!
    $condition: ModelAirportRefConditionInput
  ) {
    deleteAirportRef(input: $input, condition: $condition) {
      id
      tenant
      GMT
      codeIataAirport
      codeIcaoAirport
      codeIataCity
      codeIso2Country
      geonameId
      latitudeAirport
      longitudeAirport
      nameAirport
      nameCountry
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const createAirlineRef = /* GraphQL */ `
  mutation CreateAirlineRef(
    $input: CreateAirlineRefInput!
    $condition: ModelAirlineRefConditionInput
  ) {
    createAirlineRef(input: $input, condition: $condition) {
      id
      tenant
      callsign
      codeHub
      codeIataAirline
      codeIcaoAirline
      codeIso2Country
      founding
      nameAirline
      nameCountry
      sizeAirline
      statusAirline
      airlineType
      createdAt
      updatedAt
    }
  }
`;
export const updateAirlineRef = /* GraphQL */ `
  mutation UpdateAirlineRef(
    $input: UpdateAirlineRefInput!
    $condition: ModelAirlineRefConditionInput
  ) {
    updateAirlineRef(input: $input, condition: $condition) {
      id
      tenant
      callsign
      codeHub
      codeIataAirline
      codeIcaoAirline
      codeIso2Country
      founding
      nameAirline
      nameCountry
      sizeAirline
      statusAirline
      airlineType
      createdAt
      updatedAt
    }
  }
`;
export const deleteAirlineRef = /* GraphQL */ `
  mutation DeleteAirlineRef(
    $input: DeleteAirlineRefInput!
    $condition: ModelAirlineRefConditionInput
  ) {
    deleteAirlineRef(input: $input, condition: $condition) {
      id
      tenant
      callsign
      codeHub
      codeIataAirline
      codeIcaoAirline
      codeIso2Country
      founding
      nameAirline
      nameCountry
      sizeAirline
      statusAirline
      airlineType
      createdAt
      updatedAt
    }
  }
`;
export const createAirport = /* GraphQL */ `
  mutation CreateAirport(
    $input: CreateAirportInput!
    $condition: ModelAirportConditionInput
  ) {
    createAirport(input: $input, condition: $condition) {
      id
      gmt
      iataCode
      cityIataCode
      icaoCode
      countryIso2
      geoname_id
      latitude
      longitude
      airportName
      countryName
      phoneNumber
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const updateAirport = /* GraphQL */ `
  mutation UpdateAirport(
    $input: UpdateAirportInput!
    $condition: ModelAirportConditionInput
  ) {
    updateAirport(input: $input, condition: $condition) {
      id
      gmt
      iataCode
      cityIataCode
      icaoCode
      countryIso2
      geoname_id
      latitude
      longitude
      airportName
      countryName
      phoneNumber
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const deleteAirport = /* GraphQL */ `
  mutation DeleteAirport(
    $input: DeleteAirportInput!
    $condition: ModelAirportConditionInput
  ) {
    deleteAirport(input: $input, condition: $condition) {
      id
      gmt
      iataCode
      cityIataCode
      icaoCode
      countryIso2
      geoname_id
      latitude
      longitude
      airportName
      countryName
      phoneNumber
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const createCountryRef = /* GraphQL */ `
  mutation CreateCountryRef(
    $input: CreateCountryRefInput!
    $condition: ModelCountryRefConditionInput
  ) {
    createCountryRef(input: $input, condition: $condition) {
      id
      tenant
      codeCurrency
      codeFips
      codeIso2Country
      codeIso3Country
      continent
      nameCountry
      nameCurrency
      numericIso
      phonePrefix
      population
      createdAt
      updatedAt
    }
  }
`;
export const updateCountryRef = /* GraphQL */ `
  mutation UpdateCountryRef(
    $input: UpdateCountryRefInput!
    $condition: ModelCountryRefConditionInput
  ) {
    updateCountryRef(input: $input, condition: $condition) {
      id
      tenant
      codeCurrency
      codeFips
      codeIso2Country
      codeIso3Country
      continent
      nameCountry
      nameCurrency
      numericIso
      phonePrefix
      population
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountryRef = /* GraphQL */ `
  mutation DeleteCountryRef(
    $input: DeleteCountryRefInput!
    $condition: ModelCountryRefConditionInput
  ) {
    deleteCountryRef(input: $input, condition: $condition) {
      id
      tenant
      codeCurrency
      codeFips
      codeIso2Country
      codeIso3Country
      continent
      nameCountry
      nameCurrency
      numericIso
      phonePrefix
      population
      createdAt
      updatedAt
    }
  }
`;
export const createFlightPassengers = /* GraphQL */ `
  mutation CreateFlightPassengers(
    $input: CreateFlightPassengersInput!
    $condition: ModelFlightPassengersConditionInput
  ) {
    createFlightPassengers(input: $input, condition: $condition) {
      id
      flightNo
      flightDate
      passengers
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateFlightPassengers = /* GraphQL */ `
  mutation UpdateFlightPassengers(
    $input: UpdateFlightPassengersInput!
    $condition: ModelFlightPassengersConditionInput
  ) {
    updateFlightPassengers(input: $input, condition: $condition) {
      id
      flightNo
      flightDate
      passengers
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlightPassengers = /* GraphQL */ `
  mutation DeleteFlightPassengers(
    $input: DeleteFlightPassengersInput!
    $condition: ModelFlightPassengersConditionInput
  ) {
    deleteFlightPassengers(input: $input, condition: $condition) {
      id
      flightNo
      flightDate
      passengers
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createCommunication = /* GraphQL */ `
  mutation CreateCommunication(
    $input: CreateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    createCommunication(input: $input, condition: $condition) {
      id
      tenant
      flightNo
      flightDate
      sms
      email
      reason
      type
      medium
      recipients
      createdAt
      updatedAt
    }
  }
`;
export const updateCommunication = /* GraphQL */ `
  mutation UpdateCommunication(
    $input: UpdateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    updateCommunication(input: $input, condition: $condition) {
      id
      tenant
      flightNo
      flightDate
      sms
      email
      reason
      type
      medium
      recipients
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommunication = /* GraphQL */ `
  mutation DeleteCommunication(
    $input: DeleteCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    deleteCommunication(input: $input, condition: $condition) {
      id
      tenant
      flightNo
      flightDate
      sms
      email
      reason
      type
      medium
      recipients
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      author
      content
      title
      excerpt
      img
      status
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      author
      content
      title
      excerpt
      img
      status
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      author
      content
      title
      excerpt
      img
      status
      createdAt
      updatedAt
    }
  }
`;
