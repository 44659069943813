import 'assets/css/index.css';
import 'assets/css/tailwind.generated.css';

import Amplify, {
  Analytics,
  Auth,
  AWSCloudWatchProvider,
  Logger,
} from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import awsExports from '../src/aws-exports';
import App from './App';

const root = document.getElementById('root');

ReactDOM.render(<App />, root);

export const logger = new Logger('caasco_pax');

Amplify.configure({
  Logging: {
    logGroupName: 'caasco-air',
    logStreamName: 'caasco-air',
  },
  ...awsExports,
});
Auth.configure({
  ...awsExports,
  authenticationFlowType: 'CUSTOM_AUTH',
});

Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());
Analytics.enable();
