import { I18n } from 'aws-amplify';
import { getLocaleOrFallback } from 'helpers/getLocale';
import dict from 'locale';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import store from './redux/store';
import Routes from './routes';
import Theme from './theme/';
import GlobalStyle from './theme/globalStyles';

const persistor = persistStore(store);

const locale = getLocaleOrFallback();

I18n.setLanguage(locale);

I18n.putVocabularies(dict);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <Routes />
        </ThemeProvider>
        <Toaster />
      </PersistGate>
    </Provider>
  );
}

export default App;
