import { createGlobalStyle } from 'styled-components';

import arrowDown from './../assets/images/icons/arrowDown.png';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;    
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background-color: #fff;
    height: 100vh;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
  }
  
  h3 {
    font-size:
  }

  h4 {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
  }


  a {
    text-decoration: none;
  }  

  ul {
    list-style-type: none;
  }

/*  */
/* General */
/*  */
.textBoxAtomInfoText{
  font-size: 16px;
  text-align: center;
  margin-top: 32px;
}

.double-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.box-title-centered {
  text-align: center;
  font-weight: 600;
}

.box-subtitle-centered {
  text-align: center;
  margin: 1em auto;
}

.TextBoxAtom {
  margin: 16px 0;
}

.TextBoxAtom  h4 {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
}

.TextBoxAtom p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
}

.TextBoxAtomLargeText p {
    font-size: 28px;
    font-weight: 600;
}


.textboxatom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -8px 0;
  padding: 0;
}

.textboxatom-mediumtext {
  font-size: 22px;
  font-weight: 600;
  margin: 4px 0;
}

/*  */
/* Overlays */
/*  */

.overlayExplanationText {
  text-align: center;
  margin: 2em 0 1em 0;
  font-weight: 600;
}

/*  */
/* Header */
/*  */

  .header-all {
    background-color: ${props => props.theme.colors.navy};
    color: ${props => props.theme.colors.white};
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1000;
  }

  .header-spacer{
    height: 1.25em;
     background-color: ${props => props.theme.colors.navy};
  }

  .header-top {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1.5em 0 0.25em 0;
    justify-content: center;
    align-items: center;
    a {
      color: white;
    }

  }

  .header-top-img {
    width: 4em;
    height: auto;
    margin-right: 16px;    
  }

  .header-top-airline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.header-top-powered {
    font-size: 13px;
    
}
  .nav-bar-box {
    margin: 1em  0 auto;
    text-align: center;
    padding: 0 16px;
  }

  .nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .nav-atom {
    color: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.navy};
    border-radius: 8px 8px 0 0;
    padding: 8px;
    width: 100%;

    img {
       width: 1.5em;
    }
    
    a {
      color: ${props => props.theme.colors.navy};

    }
  }

  .active {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.navy};
    }

.infoIcon {
  right: 0;
}

/*  */
/* Login */
/*  */

.login-form-box {
  margin: 24px 0 8px 0;
  p {
    font-size: 13px;
  }
}

.login-textfieldbox {  
    margin-bottom: 14px;  
}


/*  */
/* Contact */
/*  */

.contact-titlewimg {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 6em;
    height: auto;
    margin-right: 8px; 
  }
}


/*  */
/* Vouchers */
/*  */

.vouchers-titlewimg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  img {
    width: 1.5em;
    height: auto;
    margin-right: 8px; 
  }
}


.voucher-qrcode {
  display: flex;
  flex-direction: column;
  margin: 1.5em 0 0.25em 0;
  align-items: center;
  
  img {
  width: 50%;
  margin: auto;
}

  p {
  text-align: center;
  margin-top: 8px;
}
}


/*  */
/* Financials */
/*  */
.financials-title {
  text-align: center;
  margin-bottom: 32px;
}

.align-center{
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px
}

.acceptDeclineBox-withButtons {
  margin: 3em 0 0 0;
}
.twoLineCheckmarkBox-box {
  background-color: ${props => props.theme.colors.white};
    margin: 16px 0;
    padding: 16px;
   box-shadow: 0 0 1pt 1pt ${props => props.theme.colors.mediumgrey};
    border-radius: 8px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
  justify-content: space-between;

  img{
    width: 24px;
  }
}

.twoLineCheckmarkBox-box-selected {
   background-color: ${props => props.theme.colors.white};
    margin: 16px 0;
    padding: 16px;
   box-shadow: 0 0 2pt 2pt ${props => props.theme.colors.aquagreen};
    border-radius: 8px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
  justify-content: space-between;

  img{
    width: 24px;
  }
}

.twoLineCheckmarkBox-input {
  background-color: ${props => props.theme.colors.white};
    margin: 16px 0;
    padding: 16px;
        box-shadow: 0 0 1pt 1pt ${props => props.theme.colors.mediumgrey};
    border-radius: 8px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;


  img{
    width: 24px;
  }
}

.twoLineCheckmarkBox-input-selected {
   background-color: ${props => props.theme.colors.white};
    margin: 16px 0;
    padding: 16px;
    border-radius: 8px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 2pt 2pt ${props => props.theme.colors.aquagreen};


  img{
    width: 24px;
  }
}


.twoLineCheckmarkBox-box-left {
  margin-right: 16px;
  p:first-child{
    font-size: 12px;
  }
  p:last-child{
    font-size: 24px;
  }
}

.twoLineCheckmarkBox-box-right {
  /* padding-left: 0.5rem; */
  padding-left: 16px;
  
}



  

/*  */
/* FAQ Accordion */
/*  */
.accordion-part {
  border-bottom: 1px solid ${props => props.theme.colors.mediumgrey};
  :last-child{
    border-bottom: none;
  }
}

.accordion-part__header {
    padding: 24px 8px;
    position: relative;
    cursor: pointer;
}
.accordion-part__header::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    opacity: 0.5;
		background: url(${arrowDown}) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
}
.accordion-part__header_opened::after {
    transform: rotate(-180deg);
}
.accordion-part__text {
    margin: 0;
}
.accordion-part__body {
    padding: 0 8px 16px 8px;
    position: relative;
    
}
.accordion-part__body::before {
    content: '';
    display: block;
    position: absolute;
    /* top: 0;
    left: 20px;
    width: 40px;
    height: 2px;
    background: #ff8e3c; */

}

.inputBox-label{
  color: ${props => props.theme.colors.darktext};
  margin-left: 8px
}

.info-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px
  
}

.error-box{
  display: flex;
  flex-direction: table-row;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

.error-text{
  margin-left:5px;
  color: ${props => props.theme.colors.attnred};
  font-weight: 500px;
}


.modal-title-centered {
  text-align: center;
  font-weight: 500;
}

.modal-subtitle-centered {
  text-align: center;
  font-weight: 400;
  font-size: 0.8em;
  margin-top: 10px;
  color: ${props => props.theme.colors.darktext};
}

.phoneInput-box{
  height: 40px;
  width: 100%;
  color: ${props => props.theme.colors.black};
  border: 1px solid
    ${props =>
      props.error ? props.theme.colors.attnred : props.theme.colors.lightgrey};
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  margin-bottom: 5px;
  margin-top: 8px;
  /* padding: 8px; */
  font-size: 13px;
}

.phone-select{
  border: 0;
  color: ${props => props.theme.colors.black};
  margin-right: 10px;
  outline: 0;
  padding: 8px;

}

.phone-input{
  border: 0;
  color: ${props => props.theme.colors.black};
  outline: 0

}


.country-select{
  border: 0;
  color: ${props => props.theme.colors.black};
  outline: 0;
  padding: 8px;

}

.check-box-wrapper{
  width: 26px !important;
  height: 26px !important;
  border-radius: 5px;
  border: 1px solid #000;
}

.check-box-wrapper-selected{
  width: 26px;
  height: 26px;
  border-radius: 5px;
  /* border: 1px solid #000; */
}

.box-amount-container{
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.box-amount-h2{
  //styleName: 18 / semibold;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 22px;
letter-spacing: -0.016em;
text-align: left;

}

.currency-input-container{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.0241176em;
  color : ${props => props.theme.colors.darktext};
  display: flex;
  align-items:"center"
}

.currency-input:focus{
  border: 0 !important;
  outline: 0 !important
}

.currency-input{
  border: 0;
  margin-left: 10px;
  color: ${props => props.theme.colors.black};
  outline: 0;
  font-size: 24px;
  width: 60%;
  border-bottom: 2px solid ${props => props.theme.colors.black};
}

.avios-points-input{
  border: 0;
  width: 60%;
  color: ${props => props.theme.colors.black};
  outline: 0;
  font-size: 24px;
  border-bottom: 2px solid ${props => props.theme.colors.black};
}


/* Loading Inidicator */

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;

  &.component-loader {
    margin-top: -60px;
  }

  .effect-1,
  .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid ${props => props.theme.colors.aquagreen};
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-1 {
    animation: rotate 1s ease infinite;
  }
  .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  .effect-3 {
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid ${props => props.theme.colors.aquagreen};
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .loading .effects {
    transition: all 0.3s ease;
  }
}
.fallback-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

/* Modal */


.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: rgba(0,0,0,0.3);
}

.modal-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 20%;
  max-width: 95%;
  overflow-y: auto;
  /* background-color: white; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
border-radius: 8px;
  z-index: 101;
 
}

.align-content-center{
  display: flex;
  align-items: center !important;
  justify-content: start !important
}

`;

export default GlobalStyle;
