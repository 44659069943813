import { createSlice } from '@reduxjs/toolkit';
import { createSetter } from 'helpers/create-setter';
import { pipe, prop } from 'ramda';
import { clear } from 'redux/clear';

const slice = 'auth';

const initialState = {
  auth: null,
  loading: false,
  challenge: false,
  error: '',
  person: null,
};

export const {
  actions: { setAuth, setLoading, setChallenge, setError, setPerson },
  reducer,
} = createSlice({
  initialState,
  name: slice,
  reducers: {
    setAuth: createSetter('auth'),
    setPerson: createSetter('person'),
    setLoading: createSetter('loading'),
    setChallenge: createSetter('challenge'),
    setError: createSetter('error'),
  },
  extraReducers: {
    [clear.type]: () => initialState,
  },
});

const getAuthenticationState = prop(slice);
const getChallenge = pipe(getAuthenticationState, prop('challenge'));
const getAuth = pipe(getAuthenticationState, prop('auth'));
const getPerson = pipe(getAuthenticationState, prop('person'));
const getLoading = pipe(getAuthenticationState, prop('loading'));
const getError = pipe(getAuthenticationState, prop('error'));

export {
  getAuthenticationState,
  getLoading,
  getChallenge,
  getAuth,
  getError,
  getPerson,
  slice,
};
