import { API } from 'aws-amplify';
import { call, takeEvery } from 'redux-saga/effects';

function* sendRoomAccptanceMail() {
  try {
    const apiName = 'communicationService';
    const path = `/sendRoomAcceptanceMail`;
    const data = {
      response: true,
      headers: {},
      body: {
        paxName: 'Heathrow Airport',
        passengerName: 'George Ansong',
        hotelName: 'Paloma Hotel',
        refrence: '#12345678',
        recipient: 'georgeansong7@gmail.com',
        template: 'hotelRoomAcceptance',
      },
    };
    yield call([API, 'post'], apiName, path, data);
  } catch (error) {
    console.log(error);
  }
}

const sendAcceptanceMail = payload => ({
  type: sendRoomAccptanceMail.type,
  payload,
});
sendRoomAccptanceMail.type = 'COMMUNICATION/SENDCOMMS';

function* watchSendAcceptanceMail() {
  yield takeEvery(sendRoomAccptanceMail.type, sendRoomAccptanceMail);
}

export { sendAcceptanceMail, watchSendAcceptanceMail };
