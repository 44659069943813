import { API, Auth } from 'aws-amplify';
import history from 'helpers/browser';
import { clear } from 'redux/clear';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  setAuth,
  setChallenge,
  setError,
  setLoading,
} from './authentication-reducer';

function* handleLogin({ payload }) {
  const { pnr, dob, lastname } = payload;

  var body = {
    pnr,
    dob,
    lastname,
  };

  try {
    yield put(clear());
    yield put(setLoading(true));
    yield call([API, 'post'], 'authenticationService', '/createSigninLink', {
      body,
    });
    yield put(setLoading(false));
    yield put(setChallenge(true));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError('Account does not exist'));
  }
}

const login = payload => ({ type: login.type, payload });
login.type = 'AUTHENTICATION/login';

function* watchLogin() {
  yield takeLatest(login.type, handleLogin);
}

function* handleSignOut() {
  try {
    yield call([Auth, signOut]);
    yield put(clear());
    yield call([history, history.push], '/login');
  } catch (error) {
    console.log(error);
  }
}

const signOut = payload => ({ type: signOut.type, payload });
signOut.type = 'AUTHENTICATION/signOut';

function* watchSignOut() {
  yield takeEvery(signOut.type, handleSignOut);
}

function* handleAuthorizeLogin({ payload }) {
  try {
    yield put(setLoading(true));
    yield put(setError(''));
    const [email, code] = payload.split('&');
    const user = yield call([Auth, 'signIn'], email);
    yield call([Auth, 'sendCustomChallengeAnswer'], user, code);

    const session = yield call([Auth, 'currentAuthenticatedUser']);
    yield put(
      setAuth({
        atrributes: session.attributes,
        username: session.username,
        idToken: session.signInUserSession.idToken.jwtToken,
        refreshToken: session.signInUserSession.refreshToken.token,
        accessToken: session.signInUserSession.accessToken.jwtToken,
      }),
    );
    yield call([history, history.push], '/financials');
    yield put(setLoading(false));
  } catch (error) {
    yield call([history, history.push], '/login');
    yield put(setError('Authorization link is incorrect please try again'));
    console.log(history);
    yield put(setLoading(false));
    console.log(error);
  }
}

const authorizeLogin = payload => ({ type: authorizeLogin.type, payload });
authorizeLogin.type = 'AUTHENTICATION/authorize';

function* watchAuthorizeLogin() {
  yield takeEvery(authorizeLogin.type, handleAuthorizeLogin);
}

export {
  watchLogin,
  watchSignOut,
  signOut,
  login,
  watchAuthorizeLogin,
  authorizeLogin,
  handleLogin,
};
