import FallBackSpinnerComponent from 'components/Spinner/Spinner';
import history from 'helpers/browser';
import React, { Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { Blank, Main } from '../layouts';
import routes from './route-list';

const Routes = ({ user }) => {
  const CustomRoute = props => {
    const route = props.route;
    if (!user && route.private) {
      return <Redirect to="/login" />;
    } else if (!route.private && user) {
      // ** If route has access is true and user is Logged in then redirect user to welcome
      return <Redirect to="/financials" />;
    } else {
      // ** If none of the above render component
      return <route.component {...props} />;
    }
  };
  const Layouts = { Blank, Main };

  const LayoutRoutesAndPaths = layout => {
    const LayoutRoutes = [];
    const LayoutPaths = [];

    if (routes) {
      routes.filter(route => {
        // ** Checks if Route layout or Default layout matches current layout
        if (route.layout === layout) {
          LayoutRoutes.push(route);
          LayoutPaths.push(route.path);
        }
      });
    }

    return { LayoutRoutes, LayoutPaths };
  };

  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      // ** Convert Layout parameter to Layout Component

      const LayoutTag = Layouts[layout];

      // ** Get Routes and Paths of the Layout
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

      // ** We have freedom to display different layout for different route
      // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
      // ** that we want to implement like MainLayout Minimial or Blaank
      // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag>
            <Switch>
              {LayoutRoutes.map(route => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact
                    render={props => {
                      return (
                        <Suspense fallback={<FallBackSpinnerComponent />}>
                          <CustomRoute route={route} {...props} />
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  };

  return (
    <Router history={history}>
      <Switch>
        {ResolveRoutes()}
        <Redirect from="/" to="/financials" />
      </Switch>
    </Router>
  );
};

export default Routes;
