import { createSlice } from '@reduxjs/toolkit';
import { createSetter } from 'helpers/create-setter';
import { pipe, prop } from 'ramda';
import { clear } from 'redux/clear';

const slice = 'vouchers';

const initialState = {
  loading: false,
  error: '',
  vouchers: [],
  claim: {},
  cashDispatchSuccess: false,
};

export const {
  actions: {
    setLoading,
    setClaim,
    setVouchers,
    setError,
    setCashDispatchSuccess,
  },
  reducer,
} = createSlice({
  initialState,
  name: slice,
  reducers: {
    setLoading: createSetter('loading'),
    setVouchers: createSetter('vouchers'),
    setError: createSetter('error'),
    setClaim: createSetter('claim'),
    setCashDispatchSuccess: createSetter('cashDispatchSuccess'),
  },
  extraReducers: {
    [clear.type]: () => initialState,
  },
});

const isFinZero = data => data.find(x => x?.fincompDetails?.amount === 0);

const getVouchersState = prop(slice);

const getVouchers = pipe(getVouchersState, prop('vouchers'));
const hasFinancials = pipe(getVouchersState, prop('vouchers'), isFinZero);
const getLoading = pipe(getVouchersState, prop('loading'));
const getError = pipe(getVouchersState, prop('error'));
const getClaim = pipe(getVouchersState, prop('claim'));
const getCashDispatchSuccess = pipe(
  getVouchersState,
  prop('cashDispatchSuccess'),
);

export {
  getVouchersState,
  getLoading,
  getVouchers,
  getError,
  getCashDispatchSuccess,
  getClaim,
  slice,
  hasFinancials,
};
