import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
const theme = createMuiTheme({
  palette,
  typography,
  colors: {
    white: '#fff',
    black: '#000',
    navy: '#262C56',
    aquagreen: '#70C1B3',
    darktext: '#43484B',
    darkgrey: '#858D92',
    mediumgrey: '#BFC4C9',
    lightgrey: '#EEEFF0',
    attnred: '#E61212',
    materialgrey: '#f4f6f8',

    standard: '#70C1B3',
    cancelled: '#D1495B',
    missed_connection: '#7F557D',
    downgrade: '#38566C',
    denied_boarding: '#FF8C42',
    delayed_flight: '#F6AA1C',
    first_needs: '#59A6A3',
    dmin: '#5B7026',
    fin_comp: '#247BA0',
    hotel: '#7E52A0',
    transportation: '#63A088',
    vouchers: '#EEBC05',
  },

  fontSizes: {
    large: '2em',
  },

  mediaQueries: {
    below768: `only screen and (max-width: 768px)`,
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
