import './spinner.scss';

import React from 'react';
class Spinner extends React.Component {
  render() {
    return (
      <div className="fallback-spinner">
        <div className="loading component-loader">
          <p className="effect-1 effects"></p>
          <p className="effect-2 effects"></p>
          <p className="effect-3 effects"></p>
        </div>
      </div>
    );
  }
}

export default Spinner;
