import { API } from 'aws-amplify';
import { getAuth } from 'features/authentication/authentication-reducer';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getCompany, getPerson } from './custom-query';
import {
  getUserProfile,
  setCompanyProfile,
  setUserProfile,
} from './profile-reducer';

function* handleFetchProfile() {
  try {
    const auth = yield select(getAuth);
    const res = yield call([API, 'graphql'], {
      query: getPerson,
      variables: {
        id: auth.atrributes.preferred_username,
      },
    });

    yield put(setUserProfile(res.data.getPerson));
    const userState = yield select(getUserProfile);
    const companyData = yield call([API, 'graphql'], {
      query: getCompany,
      variables: {
        id: userState.companyID,
      },
    });
    yield put(setCompanyProfile(companyData.data.getCompany));
  } catch (error) {
    console.log(error);
  }
}

const fetchProfile = payload => ({
  type: fetchProfile.type,
  payload,
});
fetchProfile.type = 'PROFILE/fetchProfile';

function* watchFetchProfile() {
  yield takeEvery(fetchProfile.type, handleFetchProfile);
}

export { watchFetchProfile, fetchProfile };
