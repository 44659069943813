import useAuthListener from 'hooks/use-auth.js';
import React from 'react';

import Router from './route-container';

const Routes = () => {
  const [user] = useAuthListener();
  return <Router user={user} />;
};

export default Routes;
