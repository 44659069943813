/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      tenant
      accountType
      businessName
      active
      photoURL
      createdAt
      updatedAt
      wallet {
        id
        accountID
        paymentsVendor
        bic
        iban
        bankName
        currency
        accountNo
        shortCode
        createdAt
        updatedAt
      }
      companies {
        items {
          id
          tenant
          companyType
          accountID
          walletID
          companyName
          currency
          active
          photoURL
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        accountType
        businessName
        active
        photoURL
        createdAt
        updatedAt
        wallet {
          id
          accountID
          paymentsVendor
          bic
          iban
          bankName
          currency
          accountNo
          shortCode
          createdAt
          updatedAt
        }
        companies {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWallet = /* GraphQL */ `
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      accountID
      paymentsVendor
      bic
      iban
      bankName
      currency
      accountNo
      shortCode
      createdAt
      updatedAt
    }
  }
`;
export const listWallets = /* GraphQL */ `
  query ListWallets(
    $filter: ModelWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        paymentsVendor
        bic
        iban
        bankName
        currency
        accountNo
        shortCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      tenant
      companyID
      identityID
      firstName
      lastName
      email
      contactNumber
      jobTitle
      active
      enableOnboarding
      permissionLevel
      permissionRegion
      country
      photoFilename
      userPreferences {
        timezone
        language
        currency
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        companyID
        identityID
        firstName
        lastName
        email
        contactNumber
        jobTitle
        active
        enableOnboarding
        permissionLevel
        permissionRegion
        country
        photoFilename
        userPreferences {
          timezone
          language
          currency
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      tenant
      companyType
      accountID
      walletID
      companyName
      address {
        addressline1
        addressline2
        town
        county
        postcode
        country
      }
      currency
      active
      primaryContact {
        name
        phone
        email
      }
      secondaryContact {
        name
        phone
        email
      }
      coordinates {
        latitude
        longitude
      }
      voucherScans {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      hotelAgreements {
        id
        tenant
        hotelAirlineID
        lastEditorID
        hotelID
        airlineID
        agreementName
        agreementDescription
        rateAccessCode
        dateIssued
        dateLastEdited
        roomType
        roomRateArray {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        mealRateArray {
          breakfast
          lunch
          dinner
        }
        agreementValidFrom
        agreementValidTo
        agreementStatus
        active
        reservationEmail
        reservationPhone
        terminal
        state
        postalCode
        isArchived
        createdAt
        updatedAt
        hotelAirline {
          id
          tenant
          airlineID
          hotelID
          lastEditorID
          contractHotel
          premiumHotel
          onShuttleRoute
          active
          starsRating
          hotelName
          printedHotelName
          address
          city
          currency
          poplSupplier
          fax
          rank
          terminal
          contactFirstName
          contactLastName
          contactEmail
          reservationEmail
          country
          postalCode
          state
          contactNumber
          contactFax
          isArchived
          createdAt
          updatedAt
        }
      }
      airlineDetails {
        compensationPreferences {
          vouchers
          financial
          transportation
          hotel
          loyaltypoints
          downgrade
        }
        communicationPreferences
        defaultPreferences {
          cancelled
          downgrade
          denied
          delay
          firstNeeds
        }
        airportIATA
        airlineIATA
      }
      photoURL
      createdAt
      updatedAt
      users {
        items {
          id
          tenant
          companyID
          identityID
          firstName
          lastName
          email
          contactNumber
          jobTitle
          active
          enableOnboarding
          permissionLevel
          permissionRegion
          country
          photoFilename
          createdAt
          updatedAt
        }
        nextToken
      }
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        companyType
        accountID
        walletID
        companyName
        address {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        currency
        active
        primaryContact {
          name
          phone
          email
        }
        secondaryContact {
          name
          phone
          email
        }
        coordinates {
          latitude
          longitude
        }
        voucherScans {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        hotelAgreements {
          id
          tenant
          hotelAirlineID
          lastEditorID
          hotelID
          airlineID
          agreementName
          agreementDescription
          rateAccessCode
          dateIssued
          dateLastEdited
          roomType
          agreementValidFrom
          agreementValidTo
          agreementStatus
          active
          reservationEmail
          reservationPhone
          terminal
          state
          postalCode
          isArchived
          createdAt
          updatedAt
        }
        airlineDetails {
          communicationPreferences
          airportIATA
          airlineIATA
        }
        photoURL
        createdAt
        updatedAt
        users {
          nextToken
        }
        people {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHotelAirline = /* GraphQL */ `
  query GetHotelAirline($id: ID!) {
    getHotelAirline(id: $id) {
      id
      tenant
      airlineID
      hotelID
      lastEditorID
      contractHotel
      premiumHotel
      onShuttleRoute
      active
      starsRating
      hotelName
      printedHotelName
      address
      city
      currency
      poplSupplier
      fax
      rank
      terminal
      contactFirstName
      contactLastName
      contactEmail
      reservationEmail
      country
      postalCode
      state
      contactNumber
      contactFax
      isArchived
      createdAt
      updatedAt
      agreements {
        items {
          id
          tenant
          hotelAirlineID
          lastEditorID
          hotelID
          airlineID
          agreementName
          agreementDescription
          rateAccessCode
          dateIssued
          dateLastEdited
          roomType
          agreementValidFrom
          agreementValidTo
          agreementStatus
          active
          reservationEmail
          reservationPhone
          terminal
          state
          postalCode
          isArchived
          createdAt
          updatedAt
        }
        nextToken
      }
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listHotelAirlines = /* GraphQL */ `
  query ListHotelAirlines(
    $filter: ModelHotelAirlineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotelAirlines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        airlineID
        hotelID
        lastEditorID
        contractHotel
        premiumHotel
        onShuttleRoute
        active
        starsRating
        hotelName
        printedHotelName
        address
        city
        currency
        poplSupplier
        fax
        rank
        terminal
        contactFirstName
        contactLastName
        contactEmail
        reservationEmail
        country
        postalCode
        state
        contactNumber
        contactFax
        isArchived
        createdAt
        updatedAt
        agreements {
          nextToken
        }
        roomRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHotelAgreement = /* GraphQL */ `
  query GetHotelAgreement($id: ID!) {
    getHotelAgreement(id: $id) {
      id
      tenant
      hotelAirlineID
      lastEditorID
      hotelID
      airlineID
      agreementName
      agreementDescription
      rateAccessCode
      dateIssued
      dateLastEdited
      roomType
      roomRateArray {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      mealRateArray {
        breakfast
        lunch
        dinner
      }
      agreementValidFrom
      agreementValidTo
      agreementStatus
      active
      reservationEmail
      reservationPhone
      terminal
      state
      postalCode
      isArchived
      createdAt
      updatedAt
      hotelAirline {
        id
        tenant
        airlineID
        hotelID
        lastEditorID
        contractHotel
        premiumHotel
        onShuttleRoute
        active
        starsRating
        hotelName
        printedHotelName
        address
        city
        currency
        poplSupplier
        fax
        rank
        terminal
        contactFirstName
        contactLastName
        contactEmail
        reservationEmail
        country
        postalCode
        state
        contactNumber
        contactFax
        isArchived
        createdAt
        updatedAt
        agreements {
          nextToken
        }
        roomRequests {
          nextToken
        }
      }
    }
  }
`;
export const listHotelAgreements = /* GraphQL */ `
  query ListHotelAgreements(
    $filter: ModelHotelAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotelAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        hotelAirlineID
        lastEditorID
        hotelID
        airlineID
        agreementName
        agreementDescription
        rateAccessCode
        dateIssued
        dateLastEdited
        roomType
        roomRateArray {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        mealRateArray {
          breakfast
          lunch
          dinner
        }
        agreementValidFrom
        agreementValidTo
        agreementStatus
        active
        reservationEmail
        reservationPhone
        terminal
        state
        postalCode
        isArchived
        createdAt
        updatedAt
        hotelAirline {
          id
          tenant
          airlineID
          hotelID
          lastEditorID
          contractHotel
          premiumHotel
          onShuttleRoute
          active
          starsRating
          hotelName
          printedHotelName
          address
          city
          currency
          poplSupplier
          fax
          rank
          terminal
          contactFirstName
          contactLastName
          contactEmail
          reservationEmail
          country
          postalCode
          state
          contactNumber
          contactFax
          isArchived
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRoomRequest = /* GraphQL */ `
  query GetRoomRequest($id: ID!) {
    getRoomRequest(id: $id) {
      id
      tenant
      requestID
      airlineID
      userID
      hotelID
      multiRoomRequestID
      HotelAirlineID
      roomsRequested
      nights
      bookingDate
      requestType
      requestStatus
      validFrom
      validUntil
      roomsConfirmed
      roomsUsed
      isArchived
      multiRequest
      createdAt
      updatedAt
    }
  }
`;
export const listRoomRequests = /* GraphQL */ `
  query ListRoomRequests(
    $filter: ModelRoomRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        requestID
        airlineID
        userID
        hotelID
        multiRoomRequestID
        HotelAirlineID
        roomsRequested
        nights
        bookingDate
        requestType
        requestStatus
        validFrom
        validUntil
        roomsConfirmed
        roomsUsed
        isArchived
        multiRequest
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDirectBooking = /* GraphQL */ `
  query GetDirectBooking($id: ID!) {
    getDirectBooking(id: $id) {
      id
      tenant
      airline
      date
      distanceFromAirport
      maxPricePerNight
      maxStarRatings
      minPricePerNight
      minStarRatings
      nights
      propertyType
      roomType
      hotelName
      rooms
      status
      createdAt
      updatedAt
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDirectBookings = /* GraphQL */ `
  query ListDirectBookings(
    $filter: ModelDirectBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        airline
        date
        distanceFromAirport
        maxPricePerNight
        maxStarRatings
        minPricePerNight
        minStarRatings
        nights
        propertyType
        roomType
        hotelName
        rooms
        status
        createdAt
        updatedAt
        vouchers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMultiRoomRequest = /* GraphQL */ `
  query GetMultiRoomRequest($id: ID!) {
    getMultiRoomRequest(id: $id) {
      id
      tenant
      airlineID
      userID
      roomsRequested
      roomsConfirmed
      nights
      requestType
      requestStatus
      validFrom
      validUntil
      hotels {
        id
        name
      }
      createdAt
      updatedAt
      roomRequests {
        items {
          id
          tenant
          requestID
          airlineID
          userID
          hotelID
          multiRoomRequestID
          HotelAirlineID
          roomsRequested
          nights
          bookingDate
          requestType
          requestStatus
          validFrom
          validUntil
          roomsConfirmed
          roomsUsed
          isArchived
          multiRequest
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listMultiRoomRequests = /* GraphQL */ `
  query ListMultiRoomRequests(
    $filter: ModelMultiRoomRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiRoomRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        userID
        roomsRequested
        roomsConfirmed
        nights
        requestType
        requestStatus
        validFrom
        validUntil
        hotels {
          id
          name
        }
        createdAt
        updatedAt
        roomRequests {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      id
      tenant
      airlineID
      lastEditorID
      flightNumber
      companyID
      flightDate
      type
      reason
      createdAt
      updatedAt
      people {
        items {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      flightID {
        items {
          id
          tenant
          claimID
          flightID
          flightNumber
          flightDate
          source
          data
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const claimByDate = /* GraphQL */ `
  query ClaimByDate(
    $airlineID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimByDate(
      airlineID: $airlineID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      tenant
      companyID
      personType
      firstName
      lastName
      claimID
      email
      contactNumber
      pnr
      dob
      status
      cabinBooked
      cabinAssigned
      deniedBoarding
      passengerNotes
      staffDetails {
        staffID
        staffNotes
      }
      createdAt
      updatedAt
      claim {
        id
        tenant
        airlineID
        lastEditorID
        flightNumber
        companyID
        flightDate
        type
        reason
        createdAt
        updatedAt
        people {
          nextToken
        }
        flightID {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      transportBookings {
        items {
          id
          tenant
          requestID
          pickup_dt
          payment_method
          service
          PersonID
          createdAt
          updatedAt
        }
        nextToken
      }
      vouchers {
        items {
          id
          tenant
          voucherID
          voucherType
          userType
          personID
          claimID
          vendorID
          companyID
          usageStatus
          dateIssued
          validFrom
          validUntil
          directBookingID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        companyID
        personType
        firstName
        lastName
        claimID
        email
        contactNumber
        pnr
        dob
        status
        cabinBooked
        cabinAssigned
        deniedBoarding
        passengerNotes
        staffDetails {
          staffID
          staffNotes
        }
        createdAt
        updatedAt
        claim {
          id
          tenant
          airlineID
          lastEditorID
          flightNumber
          companyID
          flightDate
          type
          reason
          createdAt
          updatedAt
        }
        transportBookings {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getResident = /* GraphQL */ `
  query GetResident($id: ID!) {
    getResident(id: $id) {
      id
      tenant
      companyID
      address
      firstName
      lastName
      email
      phone
      dob
      city
      hasPet
      wheelchair
      numberOfRooms
      createdAt
      updatedAt
    }
  }
`;
export const listResidents = /* GraphQL */ `
  query ListResidents(
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        companyID
        address
        firstName
        lastName
        email
        phone
        dob
        city
        hasPet
        wheelchair
        numberOfRooms
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransportBooking = /* GraphQL */ `
  query GetTransportBooking($id: ID!) {
    getTransportBooking(id: $id) {
      id
      tenant
      requestID
      pickup_dt
      payment_method
      service
      pickupLocation {
        lat
        long
        street_address
        town
        country
      }
      PersonID
      createdAt
      updatedAt
      Person {
        id
        tenant
        companyID
        personType
        firstName
        lastName
        claimID
        email
        contactNumber
        pnr
        dob
        status
        cabinBooked
        cabinAssigned
        deniedBoarding
        passengerNotes
        staffDetails {
          staffID
          staffNotes
        }
        createdAt
        updatedAt
        claim {
          id
          tenant
          airlineID
          lastEditorID
          flightNumber
          companyID
          flightDate
          type
          reason
          createdAt
          updatedAt
        }
        transportBookings {
          nextToken
        }
        vouchers {
          nextToken
        }
      }
    }
  }
`;
export const listTransportBookings = /* GraphQL */ `
  query ListTransportBookings(
    $filter: ModelTransportBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransportBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        requestID
        pickup_dt
        payment_method
        service
        pickupLocation {
          lat
          long
          street_address
          town
          country
        }
        PersonID
        createdAt
        updatedAt
        Person {
          id
          tenant
          companyID
          personType
          firstName
          lastName
          claimID
          email
          contactNumber
          pnr
          dob
          status
          cabinBooked
          cabinAssigned
          deniedBoarding
          passengerNotes
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFlightStatus = /* GraphQL */ `
  query GetFlightStatus($id: ID!) {
    getFlightStatus(id: $id) {
      id
      tenant
      claimID
      flightID
      flightNumber
      flightDate
      source
      data
      createdAt
      updatedAt
    }
  }
`;
export const listFlightStatuss = /* GraphQL */ `
  query ListFlightStatuss(
    $filter: ModelFlightStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlightStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        claimID
        flightID
        flightNumber
        flightDate
        source
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const flightByNumberAndDate = /* GraphQL */ `
  query FlightByNumberAndDate(
    $flightNumber: ID
    $flightDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlightStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flightByNumberAndDate(
      flightNumber: $flightNumber
      flightDate: $flightDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        claimID
        flightID
        flightNumber
        flightDate
        source
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      tenant
      messageID
      claimID
      recipientID
      senderID
      airlineID
      messageVendor
      messageType
      workflowStage
      messageStatus
      dateSent
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        messageID
        claimID
        recipientID
        senderID
        airlineID
        messageVendor
        messageType
        workflowStage
        messageStatus
        dateSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageById = /* GraphQL */ `
  query MessageById(
    $messageID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageById(
      messageID: $messageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        messageID
        claimID
        recipientID
        senderID
        airlineID
        messageVendor
        messageType
        workflowStage
        messageStatus
        dateSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByRecipient = /* GraphQL */ `
  query MessageByRecipient(
    $recipientID: ID
    $workflowStage: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByRecipient(
      recipientID: $recipientID
      workflowStage: $workflowStage
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        messageID
        claimID
        recipientID
        senderID
        airlineID
        messageVendor
        messageType
        workflowStage
        messageStatus
        dateSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      tenant
      voucherID
      voucherType
      userType
      personID
      claimID
      vendorID
      companyID
      usageStatus
      dateIssued
      validFrom
      validUntil
      directBookingID
      accommodationDetails {
        nights
        validUntil
        bookingDetails
        directBookingDetails
      }
      fincompDetails {
        amount
        currency
        fcOptions
        reason
        paymentReference {
          iban
          amount
          paymentID
          accountHolder
        }
      }
      tripDetails {
        tripID
        origin {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        destination {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }
        amount
        currency
        tripStatus
        transportType
        isFromAirport
      }
      lrvDetails {
        airport
        currency
        initialAmount
        currentAmount
      }
      createdAt
      updatedAt
      voucherActivity {
        items {
          id
          tenant
          airlineID
          voucherID
          vendorID
          voucherType
          currency
          amount
          flightNumber
          destination
          activityTimeStamp
          approval
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const voucherById = /* GraphQL */ `
  query VoucherById(
    $claimID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherById(
      claimID: $claimID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const voucherByPersonId = /* GraphQL */ `
  query VoucherByPersonId(
    $personID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByPersonId(
      personID: $personID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        voucherID
        voucherType
        userType
        personID
        claimID
        vendorID
        companyID
        usageStatus
        dateIssued
        validFrom
        validUntil
        directBookingID
        accommodationDetails {
          nights
          validUntil
          bookingDetails
          directBookingDetails
        }
        fincompDetails {
          amount
          currency
          fcOptions
          reason
        }
        tripDetails {
          tripID
          amount
          currency
          tripStatus
          transportType
          isFromAirport
        }
        lrvDetails {
          airport
          currency
          initialAmount
          currentAmount
        }
        createdAt
        updatedAt
        voucherActivity {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVoucherActivity = /* GraphQL */ `
  query GetVoucherActivity($id: ID!) {
    getVoucherActivity(id: $id) {
      id
      tenant
      airlineID
      voucherID
      vendorID
      voucherType
      currency
      amount
      flightNumber
      destination
      activityTimeStamp
      approval
      reason
      createdAt
      updatedAt
    }
  }
`;
export const listVoucherActivitys = /* GraphQL */ `
  query ListVoucherActivitys(
    $filter: ModelVoucherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoucherActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        voucherID
        vendorID
        voucherType
        currency
        amount
        flightNumber
        destination
        activityTimeStamp
        approval
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherActivityByType = /* GraphQL */ `
  query VoucherActivityByType(
    $voucherType: VoucherTypes
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherActivityByType(
      voucherType: $voucherType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        voucherID
        vendorID
        voucherType
        currency
        amount
        flightNumber
        destination
        activityTimeStamp
        approval
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherActivityByVoucherId = /* GraphQL */ `
  query VoucherActivityByVoucherId(
    $voucherID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherActivityByVoucherId(
      voucherID: $voucherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        voucherID
        vendorID
        voucherType
        currency
        amount
        flightNumber
        destination
        activityTimeStamp
        approval
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherActivityByVendorId = /* GraphQL */ `
  query VoucherActivityByVendorId(
    $vendorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherActivityByVendorId(
      vendorID: $vendorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        airlineID
        voucherID
        vendorID
        voucherType
        currency
        amount
        flightNumber
        destination
        activityTimeStamp
        approval
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAirportRef = /* GraphQL */ `
  query GetAirportRef($id: ID!) {
    getAirportRef(id: $id) {
      id
      tenant
      GMT
      codeIataAirport
      codeIcaoAirport
      codeIataCity
      codeIso2Country
      geonameId
      latitudeAirport
      longitudeAirport
      nameAirport
      nameCountry
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const listAirportRefs = /* GraphQL */ `
  query ListAirportRefs(
    $filter: ModelAirportRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirportRefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        GMT
        codeIataAirport
        codeIcaoAirport
        codeIataCity
        codeIso2Country
        geonameId
        latitudeAirport
        longitudeAirport
        nameAirport
        nameCountry
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airportByIata = /* GraphQL */ `
  query AirportByIata(
    $codeIataAirport: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirportRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportByIata(
      codeIataAirport: $codeIataAirport
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        GMT
        codeIataAirport
        codeIcaoAirport
        codeIataCity
        codeIso2Country
        geonameId
        latitudeAirport
        longitudeAirport
        nameAirport
        nameCountry
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airportByIcao = /* GraphQL */ `
  query AirportByIcao(
    $codeIcaoAirport: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirportRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportByIcao(
      codeIcaoAirport: $codeIcaoAirport
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        GMT
        codeIataAirport
        codeIcaoAirport
        codeIataCity
        codeIso2Country
        geonameId
        latitudeAirport
        longitudeAirport
        nameAirport
        nameCountry
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airportByName = /* GraphQL */ `
  query AirportByName(
    $nameAirport: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirportRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportByName(
      nameAirport: $nameAirport
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        GMT
        codeIataAirport
        codeIcaoAirport
        codeIataCity
        codeIso2Country
        geonameId
        latitudeAirport
        longitudeAirport
        nameAirport
        nameCountry
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airportByCountry = /* GraphQL */ `
  query AirportByCountry(
    $nameCountry: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirportRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportByCountry(
      nameCountry: $nameCountry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        GMT
        codeIataAirport
        codeIcaoAirport
        codeIataCity
        codeIso2Country
        geonameId
        latitudeAirport
        longitudeAirport
        nameAirport
        nameCountry
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAirlineRef = /* GraphQL */ `
  query GetAirlineRef($id: ID!) {
    getAirlineRef(id: $id) {
      id
      tenant
      callsign
      codeHub
      codeIataAirline
      codeIcaoAirline
      codeIso2Country
      founding
      nameAirline
      nameCountry
      sizeAirline
      statusAirline
      airlineType
      createdAt
      updatedAt
    }
  }
`;
export const listAirlineRefs = /* GraphQL */ `
  query ListAirlineRefs(
    $filter: ModelAirlineRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirlineRefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        callsign
        codeHub
        codeIataAirline
        codeIcaoAirline
        codeIso2Country
        founding
        nameAirline
        nameCountry
        sizeAirline
        statusAirline
        airlineType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airlineByIata = /* GraphQL */ `
  query AirlineByIata(
    $codeIataAirline: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirlineRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airlineByIata(
      codeIataAirline: $codeIataAirline
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        callsign
        codeHub
        codeIataAirline
        codeIcaoAirline
        codeIso2Country
        founding
        nameAirline
        nameCountry
        sizeAirline
        statusAirline
        airlineType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airlineByIcao = /* GraphQL */ `
  query AirlineByIcao(
    $codeIcaoAirline: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirlineRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airlineByIcao(
      codeIcaoAirline: $codeIcaoAirline
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        callsign
        codeHub
        codeIataAirline
        codeIcaoAirline
        codeIso2Country
        founding
        nameAirline
        nameCountry
        sizeAirline
        statusAirline
        airlineType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airlineByName = /* GraphQL */ `
  query AirlineByName(
    $nameAirline: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirlineRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airlineByName(
      nameAirline: $nameAirline
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        callsign
        codeHub
        codeIataAirline
        codeIcaoAirline
        codeIso2Country
        founding
        nameAirline
        nameCountry
        sizeAirline
        statusAirline
        airlineType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const airlineByCountry = /* GraphQL */ `
  query AirlineByCountry(
    $nameCountry: String
    $sortDirection: ModelSortDirection
    $filter: ModelAirlineRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airlineByCountry(
      nameCountry: $nameCountry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        callsign
        codeHub
        codeIataAirline
        codeIcaoAirline
        codeIso2Country
        founding
        nameAirline
        nameCountry
        sizeAirline
        statusAirline
        airlineType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAirport = /* GraphQL */ `
  query GetAirport($id: ID!) {
    getAirport(id: $id) {
      id
      gmt
      iataCode
      cityIataCode
      icaoCode
      countryIso2
      geoname_id
      latitude
      longitude
      airportName
      countryName
      phoneNumber
      timezone
      createdAt
      updatedAt
    }
  }
`;
export const listAirports = /* GraphQL */ `
  query ListAirports(
    $filter: ModelAirportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gmt
        iataCode
        cityIataCode
        icaoCode
        countryIso2
        geoname_id
        latitude
        longitude
        airportName
        countryName
        phoneNumber
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountryRef = /* GraphQL */ `
  query GetCountryRef($id: ID!) {
    getCountryRef(id: $id) {
      id
      tenant
      codeCurrency
      codeFips
      codeIso2Country
      codeIso3Country
      continent
      nameCountry
      nameCurrency
      numericIso
      phonePrefix
      population
      createdAt
      updatedAt
    }
  }
`;
export const listCountryRefs = /* GraphQL */ `
  query ListCountryRefs(
    $filter: ModelCountryRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountryRefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        codeCurrency
        codeFips
        codeIso2Country
        codeIso3Country
        continent
        nameCountry
        nameCurrency
        numericIso
        phonePrefix
        population
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const countryByCode3 = /* GraphQL */ `
  query CountryByCode3(
    $codeIso3Country: String
    $sortDirection: ModelSortDirection
    $filter: ModelCountryRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    countryByCode3(
      codeIso3Country: $codeIso3Country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        codeCurrency
        codeFips
        codeIso2Country
        codeIso3Country
        continent
        nameCountry
        nameCurrency
        numericIso
        phonePrefix
        population
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const countryByContinent = /* GraphQL */ `
  query CountryByContinent(
    $continent: String
    $nameCountry: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountryRefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    countryByContinent(
      continent: $continent
      nameCountry: $nameCountry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        codeCurrency
        codeFips
        codeIso2Country
        codeIso3Country
        continent
        nameCountry
        nameCurrency
        numericIso
        phonePrefix
        population
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlightPassengers = /* GraphQL */ `
  query GetFlightPassengers($id: ID!) {
    getFlightPassengers(id: $id) {
      id
      flightNo
      flightDate
      passengers
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listFlightPassengerss = /* GraphQL */ `
  query ListFlightPassengerss(
    $filter: ModelFlightPassengersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlightPassengerss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flightNo
        flightDate
        passengers
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommunication = /* GraphQL */ `
  query GetCommunication($id: ID!) {
    getCommunication(id: $id) {
      id
      tenant
      flightNo
      flightDate
      sms
      email
      reason
      type
      medium
      recipients
      createdAt
      updatedAt
    }
  }
`;
export const listCommunications = /* GraphQL */ `
  query ListCommunications(
    $filter: ModelCommunicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        flightNo
        flightDate
        sms
        email
        reason
        type
        medium
        recipients
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      author
      content
      title
      excerpt
      img
      status
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        content
        title
        excerpt
        img
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
