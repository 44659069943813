import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const navy = '#262C56';
const aquagreen = '#70C1B3';
const darkText = '#43484B';
const darkGrey = '#858D92';
const medGrey = '#BFC4C9';
const lightGrey = '#EEEFF0';
const attentionRed = '#E61212';
const materialGrey = '#f4f6f8';

const colorObject = {
  standard: '#70C1B3',
  cancelled: '#D1495B',
  missedConnection: '#7F557D',
  downgrade: '#38566C',
  generalCommunication: '#262C56',
  deniedBoarding: '#FF8C42',
  confirmEmailButton: '#FF8C42',
  delayedFlight: '#F6AA1C',
  firstNeeds: '#59A6A3',
  dmin: '#5B7026',
  finComp: '#247BA0',
  hotel: '#7E52A0',
  transportation: '#63A088',
  vouchers: '#EEBC05',
  white: '#FFFFFF',
};

const brandColours = {
  navy: navy,
  aquagreen: aquagreen,
  darkText: darkText,
  darkGrey: darkGrey,
  medGrey: medGrey,
  lightGrey: lightGrey,
  attentionRed: attentionRed,
  materialGrey: materialGrey,
  black: black,
  white: white,
};

const primary = {
  contrastText: white,
  dark: navy,
  main: navy,
  light: navy,
};

const secondary = {
  contrastText: white,
  dark: aquagreen,
  main: aquagreen,
  light: aquagreen,
};

const tertiary = {
  contrastText: white,
  dark: darkGrey,
  main: darkGrey,
  light: darkGrey,
};

const success = {
  contrastText: white,
  dark: colors.green[900],
  main: colors.green[600],
  light: colors.green[400],
};

const info = {
  contrastText: white,
  dark: colors.blue[900],
  main: colors.blue[600],
  light: colors.blue[400],
};

const warning = {
  contrastText: white,
  dark: colors.orange[900],
  main: colors.orange[600],
  light: colors.orange[400],
};

const error = {
  contrastText: white,
  dark: attentionRed,
  main: attentionRed,
  light: attentionRed,
};

const text = {
  primary: colors.blueGrey[900],
  secondary: colors.blueGrey[600],
  link: colors.blue[600],
};

const background = {
  default: '#FFF',
  paper: white,
};

export default {
  // objects
  colorObject,
  brandColours,
  primary,
  secondary,
  tertiary,
  success,
  info,
  warning,
  error,
  text,
  background,

  // MUI settings
  divider: colors.grey[200],
  icon: white,
};
