const getPerson = `
query GetPerson($id: ID!) {
    getPerson(id: $id) {
      companyID
      id
      tenant
      companyID
      personType
      firstName
      lastName
  }
}
`;

const getCompany = `
query GetCompany($id: ID!) {
    getCompany(id: $id) {
      tenant
      accountID
      companyType
      companyName
      currency
      photoURL
      walletID
       airlineDetails {
        compensationPreferences {
          loyaltypoints
        }
      }
      primaryContact {
          name
          phone
          email
        }
        address {
          addressline1
          addressline2
          town
          county
          postcode
          country
        }

    }
}`;

export { getCompany, getPerson };
