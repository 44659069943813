import {
  watchAuthorizeLogin,
  watchLogin,
  watchSignOut,
} from 'features/authentication/authentication-saga';
import { watchSendAcceptanceMail } from 'features/communications/communications-saga';
import { watchFetchProfile } from 'features/profile/profile-saga';
import {
  watchFetchVouchers,
  watchRejectOrAcceptVoucher,
  watchsendCashCompensation,
} from 'features/vouchers/vouchers-saga';
import { all } from 'redux-saga/effects';

function* rootSaga() {
  yield all([
    watchLogin(),
    watchSignOut(),
    watchAuthorizeLogin(),
    watchFetchProfile(),
    watchFetchVouchers(),
    watchRejectOrAcceptVoucher(),
    watchSendAcceptanceMail(),
    watchsendCashCompensation(),
  ]);
}

export { rootSaga };
