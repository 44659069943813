import { createSlice } from '@reduxjs/toolkit';
import { createSetter } from 'helpers/create-setter';
import { pipe, prop } from 'ramda';
import { clear } from 'redux/clear';

const slice = 'profile';

const initialState = {
  userProfile: {},
  companyProfile: {},
};

export const {
  actions: { setUserProfile, setCompanyProfile },
  reducer,
} = createSlice({
  initialState,
  name: slice,
  reducers: {
    setUserProfile: createSetter('userProfile'),
    setCompanyProfile: createSetter('companyProfile'),
  },
  extraReducers: {
    [clear.type]: () => initialState,
  },
});

const getProfileState = prop(slice);

const getCompanyProfile = pipe(getProfileState, prop('companyProfile'));
const getUserProfile = pipe(getProfileState, prop('userProfile'));

export { getCompanyProfile, getUserProfile, slice };
