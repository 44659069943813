import _ from 'lodash';

export const defaultLocale = 'en-GB';

/**
 * Only use CamelCase for the objects keys: i.e. en-GB should be enGb
 *
 * When adding a new language ALWAYS define a general language fallback.
 * Could be an alias of the only language variant that is available but
 * it enables the functionality to fallback from i.e. en-US to enGb, even if
 */
const i18n = {
  en: 'en_Gb', // local language fallback
};

/**
 * Checks whether translations are available for the given locale or not.
 * If not, it will use fallbacks. First a local language fallback like en-AU to en.
 * If the local language fallback isn't available it will fallback to the defaultLocale, which
 * is defined at the top of this class. Could be injected via environment variable.
 *
 * @param {string} userLocale (format: "en", "en-GB" or "en_GB")
 *                 accepts Java or ICU locale or only language part
 * @returns {string} (format "en-GB") returns only Java locale, as this is required by react-intl
 */

let userLocale = window.navigator.language;

export const getLocaleOrFallback = () => {
  const allowedLocaleFormat = /^[a-z]{2}[_|-][A-Z]{2}$|^[a-z]{2}$/;

  if (!userLocale || !allowedLocaleFormat.test(userLocale)) {
    return defaultLocale;
  }

  const language = getLanguagePartOfLocale(userLocale);

  // check if locale is available as translation, otherwise return local fallback
  if (
    !Object.keys(i18n).includes(_.camelCase(userLocale)) &&
    Object.keys(i18n).includes(language)
  ) {
    return language;
  }

  // check if locale and local fallback is available, otherwise return default locale
  if (
    !Object.keys(i18n).includes(_.camelCase(userLocale)) &&
    !Object.keys(i18n).includes(language)
  ) {
    return defaultLocale;
  }

  userLocale = userLocale.includes('_')
    ? userLocale.replace('_', '-')
    : userLocale;

  return userLocale;
};

/**
 * Returns messages according to the given locale.
 * Messages will fallback to their local language to the defaultLocale.
 * Locale can be validated by setting checkForFallback to true or beforehand with getLocaleOrFallback
 *
 * @param {string} locale
 * @param {boolean} checkForFallback Set true, if locale should be checked for validity and availability
 * @returns {{[p]}}
 */

const getLanguagePartOfLocale = locale => {
  if (locale.includes('_')) locale = locale.replace('_', '-');
  const [language] = locale.split(new RegExp('[_|-]', 'g'));

  return language;
};
