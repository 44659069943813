/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:68b3b465-13c7-48c5-9e9c-61c1aabc03af",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_KVHoDbNaw",
    "aws_user_pools_web_client_id": "38ctfk4es7m1ihceqvjv0pn5k7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://dud3n6ux5jgxrofjcvygnwt67m.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "authenticationService",
            "endpoint": "https://3nt84rz4sk.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "communicationService",
            "endpoint": "https://hsbr8h4bta.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "compensationService",
            "endpoint": "https://8cz7zptc8c.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "flightService",
            "endpoint": "https://uteg80w20m.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "transportationService",
            "endpoint": "https://fvmb3oisfl.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "hotelService",
            "endpoint": "https://e1rsxr6gw8.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "analyticsAPI",
            "endpoint": "https://6gr0tgs93c.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "voucherAPI",
            "endpoint": "https://2motgfddij.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "saveToGoogleSheet",
            "endpoint": "https://j03hgyehvb.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "financeApi",
            "endpoint": "https://n5lsfjskx3.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "caascobackend346e0e53a3fa4a5e84355342935eef9574248-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "5cad7fd9515d4969b0b4e5da733cab24",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "eu-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Brian",
                    "LanguageCode": "en-GB"
                }
            },
            "translateText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "en",
                    "targetLanguage": "fr"
                }
            }
        }
    }
};
export default awsmobile;