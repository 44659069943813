import logo from 'assets/images/placeholder/airlinelogo.png';
import React from 'react';

function MinimalLayout(props) {
  return (
    <div>
      <div className="header-all">
        <div className="header-top">
          <div className="header-top-airline">
            <img className="header-top-img" src={logo} alt="" />
          </div>
          <p className="header-top-powered">powered by Caasco</p>
        </div>

        <div className="header-spacer" />
      </div>
      <div className="mt-20 pt-20 w-full items-center flex justify-center">
        {props.children}
      </div>
    </div>
  );
}

export default MinimalLayout;
