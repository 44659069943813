import { Cache } from 'aws-amplify';

const WEB_CACHE = Cache.createInstance({
  keyPrefix: 'caasco_pax_',
});

const storage = {
  getItem(_key) {
    return Promise.resolve(WEB_CACHE.getItem(_key));
  },
  setItem(_key, value) {
    return Promise.resolve(WEB_CACHE.setItem(_key, value));
  },
  removeItem(_key) {
    return Promise.resolve(WEB_CACHE.removeItem(_key));
  },
};

export default storage;
