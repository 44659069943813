import { Auth, Hub } from 'aws-amplify';
import {
  getAuth,
  setAuth,
} from 'features/authentication/authentication-reducer';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useAuthListener() {
  const auth = useSelector(getAuth);

  const dispatch = useDispatch();

  const isUserSignedIn = useCallback(async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      dispatch(
        setAuth({
          attributes: user.attributes,
          username: user.username,
          idToken: user.signInUserSession?.idToken.jwtToken,
          refreshToken: user.signInUserSession?.refreshToken.token,
          accessToken: user.signInUserSession?.accessToken.jwtToken,
          tenant:
            user.signInUserSession?.accessToken.payload['cognito:groups'][0],
        }),
      );
    } catch (error) {
      localStorage.clear();
    }
  }, [dispatch]);

  const handleLogout = () => {
    localStorage.clear();
  };

  useEffect(() => {
    isUserSignedIn();

    Hub.listen('auth', data => {
      const { payload } = data;
      switch (payload.event) {
        case 'signIn':
          isUserSignedIn();
          break;
        case 'signOut':
          handleLogout();
          break;
        default:
          return;
      }
    });
  }, [isUserSignedIn]);
  return [auth];
}
